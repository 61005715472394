import React, { CSSProperties, useEffect, useMemo, useState, useCallback } from 'react';
import { CashTableType, RebuyPayload, Table, TableType } from '../../shared';
import { Slider, Box, Modal, ModalClose, Button, Typography, Divider } from '@mui/joy';
import { useCashContract } from '../../hook/web3/useCashContract';
import { isProd } from '../../helper/env-helper';
import useLazyApi from '../../hook/api/useLazyApi';
import Loading from '../Loading/Loading';
import palette from '../../styles/theme';
import { useTranslation } from 'react-i18next';

interface RebuyProps {
  tableType: TableType;
  tableId: string;
  playerBalance: number;
  open: boolean;
  onClose: () => void;
}

const Rebuy: React.FC<RebuyProps> = ({ tableType, tableId, playerBalance, open, onClose }) => {
  const [amount, setAmount] = useState<number>(0);
  const { t } = useTranslation('translation', { keyPrefix: 'transaction.progress.state' });
  const { isLoading, error, rebuy, reset, progressState } = useCashContract();
  const { fetch: rebuyApi } = useLazyApi<Table, RebuyPayload>('POST', 'dev/rebuy');

  const roundDecimals = useCallback((round: number, decimals: number = 2) => {
    return Number(round.toFixed(decimals));
  }, []);

  const min = useMemo(() => {
    const cashTableType = tableType as CashTableType;
    const min = Math.max(Math.ceil(roundDecimals(cashTableType.minBuyIn - playerBalance)), tableType.big);
    setAmount(min);

    return min;
  }, [tableType, playerBalance, roundDecimals]);
  const max = useMemo(() => {
    const cashTableType = tableType as CashTableType;
    return Math.floor(roundDecimals(cashTableType.maxBuyIn - playerBalance));
  }, [tableType, playerBalance, roundDecimals]);

  const step = useMemo(() => {
    return tableType.big;
  }, [tableType]);

  const marks = useMemo(() => {
    const avg = roundDecimals((max + min) / 2);

    return [
      {
        value: min,
        label: `$${min.toString()}`,
      },
      {
        value: avg,
        label: `$${avg.toString()}`,
      },
      {
        value: max,
        label: `$${max.toString()}`,
      },
    ];
  }, [min, max, roundDecimals]);

  useEffect(() => {
    reset();
  }, [open, reset, tableType, playerBalance]);

  const devRebuy = useCallback(() => {
    rebuyApi({
      amount: amount,
      tableId,
    }).catch(() => {});

    onClose();
  }, [rebuyApi, onClose, tableId, amount]);

  const rebuyToTable = useCallback(() => {
    rebuy(tableType, amount, tableId).catch((error) => {
      console.error(error);
    });
    onClose();
  }, [rebuy, onClose, amount, tableType, tableId]);

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <Box sx={styles.container}>
          <Typography sx={{ color: palette.text.primary, alignSelf: 'center', margin: 2 }} level="body-lg">
            {tableType.name}
          </Typography>
          <ModalClose color="neutral" variant="plain" />
          <Divider />
          <Box sx={styles.content}>
            {isLoading ? (
              <>
                {progressState && (
                  <Typography sx={{ color: palette.text.primary }} level="body-lg">
                    {`${t(progressState)} `}
                  </Typography>
                )}
                <Loading size={120} />
              </>
            ) : (
              <>
                <Typography sx={{ color: palette.text.primary }} level="body-sm">
                  {`Cash-in`}
                </Typography>
                <Slider
                  color="warning"
                  getAriaValueText={(v) => `$${v}`}
                  step={step}
                  defaultValue={amount}
                  value={amount}
                  min={min}
                  max={max}
                  marks={marks}
                  valueLabelDisplay="off"
                  onChange={(e, v) => setAmount(Array.isArray(v) ? v[0] : (v as number))}
                />
                <Box
                  sx={{
                    marginTop: 'auto',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {error ? (
                    <Typography sx={{ marginBottom: 1 }} textAlign="center" color="danger" level="title-sm">
                      {error}
                    </Typography>
                  ) : undefined}
                  <Button
                    disabled={amount == 0}
                    color="primary"
                    fullWidth
                    sx={styles.button}
                    variant={amount == 0 ? 'outlined' : 'soft'}
                    onClick={() => (isProd ? rebuyToTable() : devRebuy())}
                  >
                    {`Rebuy $${amount}`}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default Rebuy;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height: 275,
    backgroundColor: palette.primary.solidBg,
    borderRadius: 30,
    border: 'solid',
    borderColor: palette.primary.solidBorder,
    borderWidth: 0.5,
    boxShadow: '24',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    p: 4,
  } as CSSProperties,
  button: {
    color: palette.text.primary,
    hover: { color: 'transparent' },
  },
  lineSpace: {
    marginTop: 15,
  } as CSSProperties,
};
