import React, { useEffect, useMemo } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';
import Loading from '../Loading/Loading';
import PlayerActionsView from '../PlayerAction/PlayerActionView';
import { Styles } from '../../styles/type';
import { Box, Stack } from '@mui/joy';
import TableView from '../Table/TableView';
import { CashTablePlayer, KeyIndex, RoundStage, TableState } from '../../shared';
import { useAuthContext } from '../../context/AuthContext';
import LeaveButton from '../Exit/LeaveButton';
import SitoutButton from '../Sitout/SitoutButton';
import useLazyApi from '../../hook/api/useLazyApi';
import RebuyButton from '../Rebuy/RebuyButton';
import SoundButton from '../Sound/SoundButton';
import useMental from '../../hook/cryptography/useMental';
import useGameRules from '../../hook/games/useGameRules';
import { useReadOnlyTableEvent } from '../../hook/events/useReadOnlyTableEvent';

const CashTableMobile: React.FC = () => {
  const { state, clearState } = usePlayerContext();
  const { table, gameRound } = state;

  const { communityCardsIndexes } = useGameRules();
  const { player } = useAuthContext();
  const { revealKey } = useMental();
  const { success: isLeaved, loading, fetch: leaveRequest } = useLazyApi('POST', 'cash-table/leave');
  const { readOnly } = useReadOnlyTableEvent(table);

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const round = useMemo(() => {
    if (table?.tableState == TableState.WaitingForPlayers) {
      return undefined;
    }
    return state?.gameRound;
  }, [state, table]);

  const roundEnded = !round?.roundStage || round.roundStage >= RoundStage.Presentation;
  const myTurn = round?.currentPlayerTurn == address;

  const players: CashTablePlayer[] = useMemo(
    () => (table ? Object.keys(table.players).map((k) => table.players[k]) : []),
    [table]
  );

  const myPlayer = useMemo(() => players.find((p) => p.address == address), [players, address]);
  const isSitUp = useMemo(() => myPlayer?.status == 'sit-up' || myPlayer?.status == 'wants-to-sit-up', [myPlayer]);

  useEffect(() => {
    if (isLeaved) {
      clearState();
    }
  }, [isLeaved, clearState]);

  const leaveTable = async () => {
    if (readOnly) {
      return clearState();
    }

    if (!table) {
      return;
    }

    if (!gameRound) {
      return leaveRequest({
        tableId: table.id,
        typeId: table.type,
      }).catch(() => {});
    }

    const communityIndexes = communityCardsIndexes(gameRound.deck.numOfPlayers, table.type.gameType);
    const communityKeys = await Promise.all(
      communityIndexes.map(async (cardIndex) => {
        return {
          index: cardIndex,
          key: await revealKey(cardIndex),
        } as KeyIndex;
      })
    );
    leaveRequest({
      tableId: table.id,
      typeId: table.type,
      communityKeys,
    }).catch(() => {});
  };

  if (loading || !table) {
    return <Loading size={120} />;
  }

  return (
    <Box style={styles.contianer}>
      <Box position={'absolute'} alignSelf={'flex-start'}>
        <LeaveButton rotate={true} onClick={() => leaveTable()} />
      </Box>
      <Box position={'absolute'} alignSelf={'flex-end'}>
        <SoundButton hideVol={true} />
      </Box>

      <TableView table={table} round={round} />

      <Box display="flex" flexDirection="row" marginLeft="10px">
        <Box
          display="flex"
          alignItems={'center'}
          justifyItems={'center'}
          flexDirection="row"
          alignSelf="flex-end"
          marginBottom={'10px'}
        >
          <Stack direction={'row'} spacing={'0.7rem'} height={'35px'}>
            {!readOnly && isSitUp && <SitoutButton isSitUp={isSitUp} table={table} />}
            {!readOnly && <RebuyButton hideBtn={true} />}
          </Stack>
        </Box>
        <Box style={styles.actionContainer}>
          {!readOnly && <PlayerActionsView myTurn={myTurn} roundEnded={roundEnded} />}
        </Box>
      </Box>
    </Box>
  );
};

const styles: Styles = {
  contianer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'center',
    height: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden' /* Prevents scrolling */,
    width: '100%',
    flexDirection: 'column',
  },
  actionContainer: {
    alignItems: 'flex-end',
    flex: 1,
    display: 'flex',
    marginBottom: '20px',
    height: '36px',
  },
};

export default CashTableMobile;
