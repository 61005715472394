import React from 'react';
import { Box, Checkbox, Sheet } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import useLazyApi from '../../hook/api/useLazyApi';
import { Table } from '../../shared';

interface SitoutButtonProps {
  isSitUp: boolean;
  table: Table;
}

const SitoutButton: React.FC<SitoutButtonProps> = ({ isSitUp, table }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sit' });
  const { loading: loading1, fetch: sitinRequest } = useLazyApi('POST', 'cash-table/sit-in');
  const { loading: loading2, fetch: situpRequest } = useLazyApi('POST', 'cash-table/sit-up');

  const sitin = () => {
    if (!table) {
      return;
    }
    sitinRequest({
      tableId: table.id,
      typeId: table.typeId,
    }).catch(() => {});
  };

  const situp = () => {
    if (!table) {
      return;
    }
    situpRequest({
      tableId: table.id,
      typeId: table.typeId,
    }).catch(() => {});
  };

  return (
    <Box>
      <Sheet sx={{ padding: '6px', borderRadius: '5%', lineHeight: 0 }} variant="solid">
        <Checkbox
          disabled={loading1 || loading2}
          checked={isSitUp}
          size="md"
          onChange={(e) => (e.target.checked ? situp() : sitin())}
          color="warning"
          label={t('out')}
        />
      </Sheet>
    </Box>
  );
};

export default SitoutButton;
