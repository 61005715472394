import { useCallback, useState } from 'react';
import { useWsContext } from '../../context/WebSocketContext';
import { WsEvent } from '../../shared';

const TIMEOUT_MS = 6000;

const useWsSender = <T, K>(event: WsEvent, responseEvent: WsEvent) => {
  const { subscribe, emit, ready } = useWsContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [data, setData] = useState<T | null>(null);

  const send = useCallback(
    (payload: K) => {
      if (!subscribe || !emit || !ready) {
        setError('ws not connected');
        return;
      }

      const timeout = setTimeout(() => {
        clear();
        setError('timeout was reached');
      }, TIMEOUT_MS);

      const unsubscribe = subscribe<T>(responseEvent, (response: T) => {
        clear();
        setData(response);
      });

      const clear = () => {
        unsubscribe();
        clearTimeout(timeout);
        setLoading(false);
      };

      setLoading(true);
      emit(event, payload);

      return () => unsubscribe();
    },
    [subscribe, emit, event, responseEvent, ready]
  );

  return { send, data, error, loading, ready };
};

export default useWsSender;
