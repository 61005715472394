import { useEffect, useState } from 'react';
import { useWsContext } from '../../context/WebSocketContext';
import { Player } from '../../shared';
import useLazyApi from '../api/useLazyApi';

export const useOnlinePlayer = () => {
  const { data, loading, fetch } = useLazyApi<Player[], any>('GET', 'player/online-players');
  const [onlinePlayers, setOnlinePlayer] = useState<Player[]>([]);

  const { subscribe, ready } = useWsContext();

  useEffect(() => {
    if (loading || !data || data.length == 0) {
      return;
    }
    setOnlinePlayer(data);
  }, [loading, data, setOnlinePlayer]);

  useEffect(() => {
    if (!ready || loading) {
      return;
    }

    const unsubscribe = subscribe!<Player>('player-connected', (p) => {
      setOnlinePlayer((prev) => {
        return [...prev, p];
      });
    });

    return () => unsubscribe();
  }, [loading, ready, subscribe, setOnlinePlayer]);

  useEffect(() => {
    if (!ready || loading) {
      return;
    }

    const unsubscribe = subscribe!<Player>('player-disconnected', (player) => {
      setOnlinePlayer((prev) => {
        return prev.filter((p) => p.id !== player.id);
      });
    });

    return () => unsubscribe();
  }, [loading, ready, subscribe, setOnlinePlayer]);

  return { onlinePlayers, loading, fetch };
};
