import React, { useCallback, useEffect, useMemo } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';
import Loading from '../Loading/Loading';
import PlayerActionsView from '../PlayerAction/PlayerActionView';
import { Styles } from '../../styles/type';
import { Box, Stack } from '@mui/joy';
import TableView from '../Table/TableView';
import { RoundStage, TableState, PlayerLeaveTournamentPayload } from '../../shared';
import { useAuthContext } from '../../context/AuthContext';
import ChatButton from '../Chat/ChatButton';
//import LeaveButton from '../Exit/LeaveButton';
import useLazyApi from '../../hook/api/useLazyApi';
import SoundButton from '../Sound/SoundButton';
import { TourneyStatus } from '../Tournament/TourneyStatus';
import { useReadOnlyTableEvent } from '../../hook/events/useReadOnlyTableEvent';
import { useModal } from '../../context/ModalContext';
import { TourneyBreakTime } from '../Tournament/TourneyBreakTime';
import palette from '../../styles/theme';

const TourneyTableDesktop: React.FC = () => {
  const { state, clearState } = usePlayerContext();
  const { table, tourney, gameRound } = state;
  const { readOnly } = useReadOnlyTableEvent(table);
  const { showModal } = useModal();
  const { player } = useAuthContext();
  const {
    success: isLeaved,
    loading,
    fetch: leaveRequest,
  } = useLazyApi<void, PlayerLeaveTournamentPayload>('POST', 'tournament/leave');

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const currenttournament = useMemo(() => {
    return tourney?.find((t) => t.id == table?.tournamentId);
  }, [tourney, table]);

  const round = useMemo(() => {
    if (table?.tableState != TableState.InProgress) {
      return undefined;
    }
    return gameRound;
  }, [gameRound, table]);

  const roundEnded = !round?.roundStage || round.roundStage >= RoundStage.Presentation;
  const myTurn = round?.currentPlayerTurn == address;

  const leaveTournament = useCallback(async () => {
    if (readOnly) {
      return clearState();
    }

    if (!table) {
      return;
    }

    leaveRequest({
      tableId: table.id,
    }).catch(() => {});
  }, [clearState, leaveRequest, readOnly, table]);

  useEffect(() => {
    if (isLeaved) {
      clearState();
    }
  }, [isLeaved, clearState]);

  useEffect(() => {
    if (currenttournament?.state == 'break' && table?.tableState == TableState.Break) {
      showModal('Break Time', TourneyBreakTime, { tournament: currenttournament });
    }
  }, [showModal, currenttournament, gameRound, table]);

  if (loading || !table) {
    return <Loading size={120} />;
  }

  return (
    <Box style={styles.contianer}>
      <Box width={'360px'}>
        <TourneyStatus
          contianerType="general"
          level="body-sm"
          tournament={currenttournament}
          color={palette.text.secondary}
        />
      </Box>
      <TableView table={table} round={round} tournament={currenttournament} />

      <Box display="flex" flexDirection="row" marginLeft="10px">
        <Box
          display="flex"
          alignItems={'center'}
          justifyItems={'center'}
          flexDirection="row"
          alignSelf="flex-end"
          marginBottom={'10px'}
        >
          <Stack direction={'row'} spacing={'0.7rem'} height={'35px'}>
            {/* <LeaveButton rotate={true} onClick={() => leaveTournament()} /> */}
            {!readOnly && <ChatButton />}
            <SoundButton />
          </Stack>
        </Box>
        <Box style={styles.actionContainer}>
          {!readOnly && <PlayerActionsView myTurn={myTurn} roundEnded={roundEnded} />}
        </Box>
      </Box>
    </Box>
  );
};

const styles: Styles = {
  contianer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'center',
    height: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden' /* Prevents scrolling */,
    width: '100%',
    flexDirection: 'column',
  },
  actionContainer: {
    flex: 1,
    alignItems: 'flex-end',
    maxWidth: '420px',
    display: 'flex',
    marginBottom: '4px',
    marginRight: '10px',
    marginLeft: 'auto',
    height: '80px',
  },
};

export default TourneyTableDesktop;
