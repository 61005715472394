import React from 'react';
import { Box, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import palette from '../../styles/theme';

export const KickoutPlayerInconsistency = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'kickout.player.inconsistency' });

  return (
    <Box sx={{ maxHeight: '100px', maxWidth: '350px' }}>
      <Typography sx={{ color: palette.text.secondary, whiteSpace: 'pre-line' }} level="body-md">
        {`${t('msg')}`}
      </Typography>
    </Box>
  );
};
