import { useMemo } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { usePlayerContext } from '../../context/PlayerContext';
import { PokerActionType, RoundStage } from '../../shared';

export const usePlayerAction = (myRaise: number, autoAction: PokerActionType | null) => {
  const { state } = usePlayerContext();
  const { player } = useAuthContext();
  const round = state.gameRound;
  const table = state.table;
  const bigBlindAmount = table?.type?.big || 0;

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const currentBet = useMemo(() => {
    if (!round?.currentBets) {
      return 0;
    }
    const bets = Object.values(round?.currentBets);
    if (bets.length == 0) {
      return 0;
    }

    return Math.max(...bets);
  }, [round]);

  const myCurrentBet = useMemo(() => {
    if (round?.currentBets[address]) {
      return round?.currentBets[address];
    }
    return 0;
  }, [round, address]);

  const callAmount = useMemo(() => {
    return currentBet - myCurrentBet;
  }, [currentBet, myCurrentBet]);

  const allInAmount = useMemo(() => state.table?.players[address]?.balance || 0, [state, address]);

  const minRaise = useMemo(() => {
    const raiseHistory = round?.actionHistory
      .filter(
        (actionRecord) => actionRecord.action === PokerActionType.Raise || actionRecord.action === PokerActionType.AllIn
      )
      .map((actionRecord) => actionRecord.amount!);

    if (!raiseHistory || raiseHistory.length == 0) {
      if (round?.roundStage == RoundStage.PreFlop) {
        return bigBlindAmount * 2;
      }
      return bigBlindAmount;
    }

    let raise = bigBlindAmount;
    let lastRaiseIndex = 0;
    for (let x = 0; x < raiseHistory.length; x++) {
      const currentRaise = raiseHistory[x];
      if (x == 0) {
        raise = Math.max(raise, currentRaise);
        lastRaiseIndex = x;
        continue;
      }

      const lastRaise = raiseHistory[lastRaiseIndex];
      if (currentRaise > lastRaise) {
        raise = currentRaise - lastRaise;
        lastRaiseIndex = x;
      }
    }

    return raiseHistory[lastRaiseIndex] + raise;
  }, [bigBlindAmount, round]);

  const maxRaise = useMemo(() => state.table?.players[address]?.balance || 0, [state, address]);

  const actions = useMemo(() => {
    if (!round) {
      return [];
    }
    const balance = state.table?.players[address]?.balance || 0;
    const isAllIn =
      myRaise === balance || balance < minRaise || currentBet > balance || autoAction == PokerActionType.AllIn;
    const raiseOrAllin = isAllIn ? PokerActionType.AllIn : PokerActionType.Raise;
    const canCall = balance > currentBet && callAmount > 0;

    const actions = [PokerActionType.Fold];
    if (currentBet > 0) {
      if (callAmount == 0) {
        actions.push(PokerActionType.Check);
      } else if (canCall) {
        actions.push(PokerActionType.Call);
      }
    } else {
      actions.push(PokerActionType.Check);
    }
    actions.push(raiseOrAllin);

    return actions;
  }, [state, round, address, myRaise, currentBet, callAmount, minRaise, autoAction]);

  const canRaise = useMemo(() => {
    const balance = state.table?.players[address]?.balance || 0;
    const canRaiseAmount = balance > callAmount + minRaise;
    const isAllIn = myRaise === balance;
    return actions.some((a) => a === PokerActionType.Raise) && canRaiseAmount && !isAllIn;
  }, [actions, callAmount, minRaise, myRaise, state, address]);

  return { actions, canRaise, minRaise, maxRaise, myCurrentBet, callAmount, allInAmount };
};
