import { Box } from '@mui/joy';
import React, { useMemo } from 'react';
import { Pot } from '../../shared';
import { Styles } from '../../styles/type';
import Text from '../Text/Text';
import { useTranslation } from 'react-i18next';
import { useChipsFormat } from '../../hook/games/useChipsFormat';

export interface TotalPotsProps {
  pots?: Pot[];
}

const TotalPots: React.FC<TotalPotsProps> = ({ pots }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pot' });
  const { chipsFormat } = useChipsFormat();

  const actualPots = useMemo(() => {
    if (!pots || pots.length == 0) {
      return null;
    }
    return pots.filter((p) => p.eligiblePlayers.length > 1);
  }, [pots]);

  const totalPotsAmount = useMemo(() => {
    if (!actualPots) {
      return 0;
    }

    return actualPots.reduce((total, pot) => {
      return total + pot.amount;
    }, 0);
  }, [actualPots]);

  if (!actualPots) {
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {totalPotsAmount > 0 && actualPots?.length > 1 && (
        <Text style={styles.chipsAmount}>{`${t('total')}: ${chipsFormat(totalPotsAmount)}`}</Text>
      )}
    </Box>
  );
};

const styles: Styles = {
  chipsAmount: {
    color: 'white',
  },
};

export default TotalPots;
