import React, { ReactNode, useMemo } from 'react';
import { TournamentEvent } from '../../shared';
import palette from '../../styles/theme';

interface PlayerAvatarProps {
  tournament?: TournamentEvent;
  playerAddress: string;
  children: ReactNode;
}

const PlayerRank: React.FC<PlayerAvatarProps> = ({ tournament, playerAddress, children }) => {
  const tournamentPlayer = useMemo(() => {
    return tournament?.players.find((tp) => tp.address.toLocaleLowerCase() === playerAddress.toLocaleLowerCase());
  }, [tournament, playerAddress]);

  const rankColor = useMemo(() => {
    const playerRank = tournamentPlayer?.rank;

    let borderColor = palette.neutral.solidBg;
    if (playerRank === 1) {
      borderColor = 'gold';
    } else if (playerRank === 2) {
      borderColor = 'silver';
    } else if (playerRank === 3) {
      borderColor = '#cd7f32'; // Bronze color code
    }

    return borderColor;
  }, [tournamentPlayer]);

  return (
    <div
      style={{
        borderRadius: '50%',
        border: rankColor ? `5px solid ${rankColor}` : 'none',
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  );
};

export default PlayerRank;
