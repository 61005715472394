import { Box, Select, Option, ListItemDecorator } from '@mui/joy';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useWagmiContext } from '../../context/WagmiContext';
import { Token } from '../../hook/web3/type';
import { useNetworkConfig } from '../../hook/web3/useNetworkConfig';
import { useTokenBalance } from '../../hook/web3/useTokenBalance';
import { Styles } from '../../styles/type';
import { CoinIcon } from './CoinIcon';
import { useSmartAccount } from '../../hook/web3/useSmartAccount';

interface SelectTokenProps {}

export const SelectToken: React.FC<SelectTokenProps> = () => {
  const { getNetworkConfig } = useNetworkConfig();
  const { formatted } = useTokenBalance();
  const { setToken, token } = useWagmiContext();
  const { chainId } = useSmartAccount();

  const networkTokens = useMemo(() => {
    return getNetworkConfig(chainId.toString())?.tokens;
  }, [getNetworkConfig, chainId]);

  const handleSelect = useCallback(
    (token: Token | null) => {
      if (!token) {
        return;
      }
      setToken(token);
    },
    [setToken]
  );

  useEffect(() => {
    if (networkTokens) {
      handleSelect(networkTokens[0]);
    }
  }, [networkTokens, handleSelect]);

  return (
    <Box>
      <Select
        sx={{ borderRadius: 20, borderWidth: 0.5, minHeight: '40px', height: '40px', maxHight: '40px' }}
        indicator={null}
        endDecorator={null}
        variant="solid"
        size="lg"
        value={token}
        onChange={(event, v) => handleSelect(v)}
        renderValue={() => {
          return (
            <>
              {token ? (
                <Box display="flex" flexDirection="row" alignItems="center" justifyItems="center">
                  <CoinIcon name={token.symbol} chainId={chainId} />
                  <div style={styles.balance}>{formatted || '0.00'}</div>
                </Box>
              ) : undefined}
            </>
          );
        }}
      >
        {networkTokens?.map((token) => (
          <Option key={token.symbol} value={token} label={token.symbol}>
            <ListItemDecorator>
              <CoinIcon name={token.symbol} chainId={chainId} />
            </ListItemDecorator>
            {token.symbol}
          </Option>
        ))}
      </Select>
    </Box>
  );
};

const styles: Styles = {
  balance: {
    marginLeft: '0.5vw',
    marginRight: '0.2vw',
  },
};
