"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MentalCryptoBase = void 0;
class MentalCryptoBase {
    constructor() {
        this.PRIME_NUMBER = 7919; // Example modulus (a large prime number)
        this.MIN_SHARES = 2;
        this.TOTAL_SHARES = 6;
        // public async shareKey(key: string, totalShares: number, threshold: number) {
        //   const parts = secret.share(key, totalShares, threshold);
        //   return parts;
        // }
        // public async combineKey(shares: string[]) {
        //   const pk = secret.combine(shares);
        //   return pk.toString();
        // }
    }
    generateKey() {
        let key;
        do {
            key = this.randomBigInt(32);
        } while (!this.isValidKey(key));
        return key.toString();
    }
    encrypt(card, key) {
        let result = BigInt(1);
        let baseBigInt = BigInt(card);
        let exponentBigInt = key;
        let modulusBigInt = BigInt(this.PRIME_NUMBER);
        while (exponentBigInt > 0n) {
            if (exponentBigInt % 2n === 1n) {
                result = (result * baseBigInt) % modulusBigInt;
            }
            exponentBigInt = exponentBigInt / 2n;
            baseBigInt = (baseBigInt * baseBigInt) % modulusBigInt;
        }
        return Number(result);
    }
    decrypt(card, key) {
        const inverseKey = this.modInverse(key);
        const encryptedValueBig = BigInt(card);
        const modulus = BigInt(this.PRIME_NUMBER);
        const decrypted = encryptedValueBig ** inverseKey % modulus;
        return Number(decrypted);
    }
    modInverse(key) {
        let [a, m] = [key, BigInt(this.PRIME_NUMBER - 1)];
        if (m === 1n)
            return 0n;
        let [m0, x0, x1] = [m, 0n, 1n];
        while (a > 1n) {
            let q = a / m;
            [m, a] = [a % m, m];
            [x0, x1] = [x1 - q * x0, x0];
        }
        return (x1 + m0) % m0;
    }
    gcd(a, b) {
        if (b === 0n)
            return a;
        return this.gcd(b, a % b);
    }
    isValidKey(key) {
        const bigPrime = BigInt(this.PRIME_NUMBER);
        // Calculate the GCD
        const gcdValue = this.gcd(key, bigPrime - 1n);
        // K is valid if GCD(K, P-1) is 1
        return gcdValue === 1n;
    }
}
exports.MentalCryptoBase = MentalCryptoBase;
