import { useCallback, useEffect, useMemo } from 'react';
import { useWsContext } from '../../context/WebSocketContext';
import { useBackupKeys } from '../events/useBackupKey';
import {
  Card,
  DealPlayerCardsKeysEvent,
  RevealKeyCommunityCardsRequest,
  GameRoundPlayer,
  PlayerRevealHisCardsPayload,
  EncryptDeckEvent,
  CardsDeck,
  KeyIndex,
  CardKeys,
  RevealKeyCommunityCardsResponse,
  PlayerRevealCardsEvent,
  DealPlayerCardsKeysResponse,
} from '../../shared';
import { useGameValidation } from '../games/useGameValidation';
import { useP2PEventContext } from '../../context/P2PEventContext';
import { usePlayerContext } from '../../context/PlayerContext';
import useMental from '../cryptography/useMental';
import useGameRules from '../games/useGameRules';
import useRevealMyCards from './useRevealMyCards';

export const useDealerEventHandler = () => {
  const { backup } = useBackupKeys();

  const { reveal } = useRevealMyCards();
  const { subscribe, emit } = useWsContext();
  const { encryptDeck, decryptCard, shuffle, revealKey, replaceKey, revealCards } = useMental();
  const { communityCardsIndexes, nextCommunityCardsIndexes } = useGameRules();
  const { validateRevealCards, validatePlayerRevealHisCardEvent } = useGameValidation();
  const { subscribe: subscribeP2P } = useP2PEventContext();
  const {
    updateState,
    syncTableRound,
    state: { gameRound, playerRevealCards, table },
  } = usePlayerContext();
  const gameType = useMemo(() => table?.type.gameType, [table]);

  // ******  shuffle-deck  *******

  useEffect(() => {
    if (!subscribe || !emit) {
      return;
    }

    const unsubscribe = subscribe<CardsDeck>('shuffle-deck-request', async (payload: CardsDeck) => {
      const shuffledDeck = shuffle(payload.cards);
      payload.cards = shuffledDeck;
      emit<CardsDeck>('shuffle-deck-response', payload);
    });

    return () => unsubscribe();
  }, [subscribe, emit, shuffle]);

  // ******  encrypt-deck *******

  useEffect(() => {
    if (!subscribe || !emit || !gameType) {
      return;
    }

    const unsubscribe = subscribe('encrypt-deck-request', async (payload: EncryptDeckEvent) => {
      const deck = payload.deck;
      const { reEncryptedCards, backupKeys } = await encryptDeck(gameType, deck.cards, payload.deck.numOfPlayers);
      deck.cards = reEncryptedCards;
      backup(backupKeys, deck.roundId);
      emit<CardsDeck>('encrypt-deck-response', deck);
    });

    return () => unsubscribe();
  }, [subscribe, emit, backup, encryptDeck, gameType]);

  // ******  handle-player-reveal-his-cards-p2p *******

  useEffect(() => {
    if (!subscribeP2P || !gameRound) {
      return;
    }

    const unsubscribe = subscribeP2P<PlayerRevealHisCardsPayload>('player-reveal-his-cards', async (_, message) => {
      const playerAddress = message.from;
      console.debug(`recieved player reveal his cards event from ${playerAddress}`);

      const cardsKeys = message.payload.cardsKeys;

      try {
        const cards: Card[] = cardsKeys.map((ck) => {
          let card = gameRound.deck.cards[ck.index];
          for (const key of ck.keys) {
            card = decryptCard(card, [key]);
          }
          return card;
        });

        if (!validateRevealCards(cards)) {
          console.error('validateRevealCards validation failed');
          return;
        }

        const updatedPlayerRevealCards = playerRevealCards || {};
        updatedPlayerRevealCards[message.from] = cards;

        updateState({
          playerRevealCards: {
            ...updatedPlayerRevealCards,
          },
        });
      } catch (error) {
        console.error(`failed to handle player reveal his crads event with error: ${error}`);
      }
    });

    return () => unsubscribe();
  }, [
    gameRound,
    playerRevealCards,
    subscribeP2P,
    decryptCard,
    syncTableRound,
    validatePlayerRevealHisCardEvent,
    validateRevealCards,
    updateState,
  ]);

  // ******  deal-player-cards-keys  ******

  useEffect(() => {
    if (!subscribe || !emit || !gameType) {
      return;
    }

    const unsubscribe = subscribe<DealPlayerCardsKeysEvent>(
      'deal-player-cards-keys-request',
      async (payload: DealPlayerCardsKeysEvent) => {
        const { players, serverPublicKey } = payload;

        const response: DealPlayerCardsKeysResponse = {
          playerKeys: {},
          communityKeys: [],
        };

        for (const player of players) {
          response.playerKeys[player.address] = [];
          for (const cardIndex of player.cardsIndexes) {
            const key = await replaceKey(cardIndex, player.player.encryptionPublicKey);
            response.playerKeys[player.address].push({
              index: cardIndex,
              key,
            });
          }
        }

        const communityCards = communityCardsIndexes(players.length, gameType);
        for (const cardIndex of communityCards) {
          const key = await replaceKey(cardIndex, serverPublicKey);
          response.communityKeys.push({
            index: cardIndex,
            key,
          });
        }
        emit<DealPlayerCardsKeysResponse>('deal-player-cards-keys-response', response);
      }
    );

    return () => unsubscribe();
  }, [subscribe, emit, replaceKey, communityCardsIndexes, gameType]);

  // ******  player-decrpt-his-cards-test  ******

  const playerDecryptHisCardsTestEventHandler = useCallback(
    async (player: GameRoundPlayer, deck: CardsDeck) => {
      try {
        const cardKeys: CardKeys[] = player.cardsIndexes.map((index) => {
          return {
            index,
            keys: player.encryptedKeys[index],
          };
        });

        const cards = await revealCards(cardKeys, deck);
        if (!cards || !validateRevealCards(cards.map((c) => c.card))) {
          console.error(`Misdeal - falid to reveal cards: ${JSON.stringify(cards)}`);
          return false;
        }

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    [revealCards, validateRevealCards]
  );

  useEffect(() => {
    if (!subscribe || !emit) {
      return;
    }

    const unsubscribe = subscribe<PlayerRevealCardsEvent>(
      'player-decrypt-his-cards-test-request',
      async (payload: PlayerRevealCardsEvent) => {
        const { player, deck } = payload;
        const response = await playerDecryptHisCardsTestEventHandler(player, deck);
        emit<boolean>('player-decrypt-his-cards-test-response', response);
      }
    );

    return () => unsubscribe();
  }, [subscribe, emit, playerDecryptHisCardsTestEventHandler]);

  // ******  reveal-key-community-cards ******

  useEffect(() => {
    if (!subscribe || !emit || !gameRound || !gameType) {
      return;
    }

    const unsubscribe = subscribe(
      'reveal-key-community-cards-request',
      async (payload: RevealKeyCommunityCardsRequest) => {
        const { stage } = payload;
        const cardsIndexes = nextCommunityCardsIndexes(stage, gameRound.players.length, gameType);

        const keys = await Promise.all(
          cardsIndexes.map(async (index) => {
            return {
              index,
              key: await revealKey(index),
            } as KeyIndex;
          })
        );

        emit<RevealKeyCommunityCardsResponse>('reveal-key-community-cards-response', { keys, succuss: true });
      }
    );

    return () => unsubscribe();
  }, [gameRound, gameType, subscribe, emit, revealKey, nextCommunityCardsIndexes]);

  // ******  player-time-to-reveal-cards ******

  useEffect(() => {
    if (!subscribe || !emit) {
      return;
    }

    const unsubscribe = subscribe('player-time-to-reveal-cards-request', async () => {
      const myCards = await reveal();
      emit<CardKeys[] | null>('player-time-to-reveal-cards-response', myCards);
    });

    return () => unsubscribe();
  }, [subscribe, emit, reveal]);
};
