import React from 'react';
import { CashTablePlayer } from '../../shared';
import { usePlayerContext } from '../../context/PlayerContext';
import BetChips from '../Chip/BetChips';

interface PokerTableProps {
  player: CashTablePlayer;
}

const PlayerBet: React.FC<PokerTableProps> = ({ player }) => {
  const { state } = usePlayerContext();
  const currentBet = state.gameRound?.currentBets[player.address];
  const smallBlind = state.table?.type?.small || 0.5;

  return <>{currentBet && currentBet > 0 ? <BetChips amount={currentBet} smallBlind={smallBlind} /> : undefined}</>;
};

export default PlayerBet;
