import { useEffect } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';
import { useWsContext } from '../../context/WebSocketContext';
import { FuncComposite } from '../../helper/composite';
import { TournamentEvent } from '../../shared';
import { useSnackbar } from '../../context/SnackbarContext';
import { useModal } from '../../context/ModalContext';
import { TourneyPlayerResult } from '../../components/Tournament/TourneyPlayerResult';
import TournamentDeposit from '../../components/Deposit/TournamentDeposit';

export const useTourneyEventHandler = () => {
  const { subscribe } = useWsContext();
  const { showMessage } = useSnackbar();
  const { showModal, close } = useModal();
  const { addTourney, removeTourney, clearState } = usePlayerContext();

  useEffect(() => {
    if (!subscribe) {
      return;
    }

    const unsubscribe = new FuncComposite();

    unsubscribe.add(
      subscribe<TournamentEvent>('tournament-event-changed', (payload: TournamentEvent) => {
        if (payload.state == 'ended') {
          removeTourney(payload);
        } else {
          addTourney(payload);
        }
      })
    );

    unsubscribe.add(
      subscribe<TournamentEvent>('tournament-player-register', () => {
        showMessage('Registered Successfully', 'success');
        close();
      })
    );

    unsubscribe.add(
      subscribe<TournamentEvent>('tournament-player-unregister', (payload: TournamentEvent) => {
        removeTourney(payload);
        showMessage('Unregistered Successfully', 'success');
        close();
      })
    );

    unsubscribe.add(
      subscribe<TournamentEvent>('tournament-player-eliminated', (payload: TournamentEvent) => {
        removeTourney(payload);
        clearState();

        if (payload.state == 'started') {
          showModal('Re-Entry', TournamentDeposit, { tournamentEvent: payload });
        } else {
          showModal('Tournament Result', TourneyPlayerResult, { tourneyEvent: payload });
        }
      })
    );

    unsubscribe.add(
      subscribe<TournamentEvent>('tournament-ended', (payload: TournamentEvent) => {
        removeTourney(payload);
        clearState();
      })
    );

    unsubscribe.add(
      subscribe<TournamentEvent>('tournament-canceled', (payload: TournamentEvent) => {
        removeTourney(payload);
        clearState();
        showMessage('Tournament Canceled', 'warning');
      })
    );

    return () => unsubscribe.run();
  }, [subscribe, showModal, close, showMessage, clearState, addTourney, removeTourney]);
};
