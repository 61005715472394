import { useCallback } from 'react';
import { Card } from '../../shared';

export const useCardText = () => {
  const splitCard = useCallback((card: Card) => {
    if (card < 2 || card > 53) {
      console.error(`Card(${card}) is not a valid card`);
      return { rank: -1, suit: -1 };
    }
    const rank = Math.floor((card - 2) / 4) + 2;
    const suit = card % 4;

    return {
      rank,
      suit,
    };
  }, []);

  const getRankSymbol = useCallback((rank: number): string => {
    if (rank <= 10) {
      return rank + '';
    }
    switch (rank) {
      case 11:
        return 'J';
      case 12:
        return 'Q';
      case 13:
        return 'K';
      case 14:
        return 'A';
    }
    return 'N/A';
  }, []);

  const getSuitSymbol = useCallback((suit: number): string => {
    switch (suit) {
      case 0:
        return '♣️';
      case 1:
        return '♦️';
      case 2:
        return '♥️';
      case 3:
        return '♠️';
    }
    return '';
  }, []);

  const cardColor = useCallback(
    (card: Card): string => {
      const { suit } = splitCard(card);

      if (suit == 0 || suit == 3) {
        return 'black';
      }
      return 'red';
    },
    [splitCard]
  );

  const cardToText = useCallback(
    (card: Card) => {
      const { rank, suit } = splitCard(card);
      const rankSymbol = getRankSymbol(rank);

      return `${rankSymbol}${getSuitSymbol(suit)}`;
    },
    [splitCard, getRankSymbol, getSuitSymbol]
  );

  return { splitCard, getRankSymbol, getSuitSymbol, cardColor, cardToText };
};
