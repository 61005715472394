import React from 'react';
import { Box } from '@mui/joy';
import { motion } from 'framer-motion';
import { usePlayerContext } from '../../context/PlayerContext';
import { CashTablePlayer } from '../../shared';
import Text from '../Text/Text';
import { Styles } from '../../styles/type';
import palette from '../../styles/theme';
import { usePlayerNickname } from '../../hook/helper/usePlayerNickname';
import { useChipsFormat } from '../../hook/games/useChipsFormat';

export interface PlayerStateProps {
  player: CashTablePlayer;
  visible: boolean;
}

const PlayerState: React.FC<PlayerStateProps> = ({ player, visible }) => {
  const { state } = usePlayerContext();
  const { chipsFormat } = useChipsFormat();
  const { nickname } = usePlayerNickname(player.player);
  const chipsAmount = state.table?.players[player.address]?.balance || 0;

  const flashAnimation = {
    boxShadow: visible ? '0px 0px 0px 4px rgba(255, 255, 255, 0.8)' : 'none', // White glow
    transition: visible
      ? {
          duration: 0.5,
          repeat: Infinity,
          repeatType: 'reverse' as const,
        }
      : {},
  };

  return (
    <Box sx={styles.container}>
      <motion.div animate={flashAnimation} style={styles.flash}>
        {player.status == 'sit-up' && <Text style={styles.stausText}>{'sit out'}</Text>}
        <Text style={styles.text}>{nickname}</Text>
        <Text style={styles.text}>{chipsFormat(chipsAmount)}</Text>
      </motion.div>
    </Box>
  );
};

const styles: Styles = {
  container: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    flexDirection: 'column' as const,
    backgroundColor: palette.primary.softBg,
    borderRadius: '0px 0px 20px 20px',
    display: 'flex',
    width: '100%',
    zIndex: 50,
    padding: '3px',
  },
  text: {
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    justifySelf: 'center',
  },
  stausText: {
    color: palette.text.secondary,
    textAlign: 'center',
    justifyContent: 'center',
    justifySelf: 'center',
  },
  flash: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0px 0px 15px 15px',
    width: '100%',
  },
};

export default PlayerState;
