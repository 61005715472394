import React, { useCallback, useMemo } from 'react';
import { Box, Button, Slider } from '@mui/joy';
import palette from '../../styles/theme';
import { useChipsFormat } from '../../hook/games/useChipsFormat';
import { usePlayerContext } from '../../context/PlayerContext';
import { PokerActionType } from '../../shared';

interface RaiseSliderProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  minRaise: number;
  maxRaise: number;
  bb: number; // Big Blind value
  step: number;
  loading: boolean;
  horizontal?: boolean;
  isPreflop: boolean;
  potSize: number;
  raise: (amount: number) => void;
  allin: () => void;
}

interface buttonValue {
  label: string;
  value: number;
}

const RaiseSlider: React.FC<RaiseSliderProps> = ({
  value,
  setValue,
  minRaise,
  maxRaise,
  bb,
  step,
  loading,
  horizontal = false,
  isPreflop,
  potSize,
  raise,
  allin,
}) => {
  // Calculate button values using useMemo to recompute only when dependencies change
  const { round, chipsFormat } = useChipsFormat();
  const {
    state: { gameRound },
  } = usePlayerContext();

  const hasRaise = useMemo(() => {
    const raiseHistory = gameRound?.actionHistory
      .filter(
        (actionRecord) => actionRecord.action === PokerActionType.Raise || actionRecord.action === PokerActionType.AllIn
      )
      .map((actionRecord) => actionRecord.amount!);

    if (raiseHistory?.length && raiseHistory.length > 0) {
      return true;
    }

    return false;
  }, [gameRound]);

  const buttonValues = useMemo(() => {
    if (hasRaise) {
      return [
        { label: chipsFormat(minRaise), value: round(minRaise) },
        { label: chipsFormat(1.5 * minRaise), value: round(1.5 * minRaise) },
        { label: chipsFormat(2 * minRaise), value: round(2 * minRaise) },
        { label: chipsFormat(2.5 * minRaise), value: round(2.5 * minRaise) },
        { label: chipsFormat(3 * minRaise), value: round(3 * minRaise) },
        { label: `ALL-IN`, value: maxRaise },
      ];
    }

    if (isPreflop) {
      return [
        { label: chipsFormat(2 * bb), value: round(2 * bb) },
        { label: chipsFormat(3 * bb), value: round(3 * bb) },
        { label: chipsFormat(4 * bb), value: round(4 * bb) },
        { label: chipsFormat(5 * bb), value: round(5 * bb) },
        { label: chipsFormat(6 * bb), value: round(6 * bb) },
        { label: `ALL-IN`, value: maxRaise },
      ];
    } else {
      // Postflop and no one has bet
      return [
        { label: chipsFormat(potSize / 3), value: round(potSize / 3) },
        { label: chipsFormat(potSize / 2), value: round(potSize / 2) },
        { label: chipsFormat((2 * potSize) / 3), value: round((2 * potSize) / 3) },
        { label: chipsFormat(potSize), value: round(potSize) },
        { label: chipsFormat(2 * potSize), value: round(2 * potSize) },
        { label: `ALL-IN`, value: maxRaise },
      ];
    }
  }, [chipsFormat, round, isPreflop, bb, maxRaise, potSize, minRaise, hasRaise]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const handleButtonClick = (amount: number) => {
    setValue(amount);
    raise(amount);
  };

  const isHidden = useCallback(
    (item: buttonValue) => loading || item.value > round(maxRaise) || item.value < round(minRaise),
    [round, loading, maxRaise, minRaise]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: horizontal ? 'column' : 'row',
        alignItems: horizontal ? 'center' : 'flex-end',
        justifyContent: horizontal ? 'center' : 'flex-end',
        marginBottom: '2px',
      }}
    >
      {/* Render buttons below the slider */}
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          justifyContent: 'flex-end',
          height: horizontal ? 'auto' : 184,
          right: 40,
          bottom: 70,
          flexDirection: horizontal ? 'row' : 'column-reverse',
          marginBottom: 'auto',
          gap: 0.1,
          width: horizontal ? '100%' : 'auto',
        }}
      >
        {buttonValues
          .filter((item) => !isHidden(item))
          .map((item) => (
            <Button
              key={item.value}
              size="sm"
              onClick={() => (item.label == 'ALL-IN' ? allin() : handleButtonClick(item.value))}
              sx={{
                color: palette.text.secondary,
                minWidth: 0,
                padding: '6px',
                whiteSpace: 'nowrap', // Prevent line breaks
                overflow: 'hidden', // Ensure text doesn't overflow the button
                textOverflow: 'ellipsis', // Add ellipsis if text is too long
                textAlign: 'center', // Center the text
              }}
            >
              {item.label}
            </Button>
          ))}
      </Box>
      <Slider
        orientation={horizontal ? 'horizontal' : 'vertical'}
        value={value}
        onChange={handleSliderChange}
        min={minRaise}
        max={maxRaise}
        disabled={loading}
        size="sm"
        sx={{
          marginRight: 0,
          marginBottom: '8px',
          width: horizontal ? '100%' : 'auto',
          height: horizontal ? 'auto' : 220,
          '& .MuiSlider-thumb': {
            width: 24,
            height: 24,
            backgroundColor: palette.text.secondary,
            borderRadius: '50%',
          },
          '& .MuiSlider-track': {
            backgroundColor: palette.text.secondary,
          },
        }}
        step={step}
      />
    </Box>
  );
};

export default RaiseSlider;
