import { useState, useCallback, useEffect } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, Method } from 'axios';
import { Route } from '../type';
import { useAuthContext } from '../../context/AuthContext';

interface ApiHook<T> {
  data: T | null;
  loading: boolean;
  refetch: () => Promise<void>;
  error: AxiosError | null;
}

interface Options extends AxiosRequestConfig {
  refetchInterval?: number;
}

const baseUrl = process.env.REACT_APP_SERVER_HOST;
const protocol = process.env.REACT_APP_HTTPS == 'true' ? 'https://' : 'http://';

const useApi = <T, K>(method: Method, route: Route, body?: K, opt?: Options): ApiHook<T> => {
  const [bodyState] = useState(body);
  const [data, setData] = useState<T | null>(null);
  const { clearAuth } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError | null>(null);

  const fetchData = useCallback(async () => {
    const options = opt || ({} as Options);
    options.withCredentials = true;
    const url = `${protocol}${baseUrl}/${route}`;
    try {
      let response: AxiosResponse<T>;
      if (method === 'POST') {
        response = await axios.post<T>(url, bodyState, options);
      } else {
        response = await axios.get<T>(url, options);
      }
      setData(response.data);
    } catch (error) {
      const axiosError = error as AxiosError;
      setError(axiosError);
      if (axiosError?.response?.status === 401) {
        clearAuth();
      }
    } finally {
      setLoading(false);
    }
  }, [route, method, bodyState, clearAuth]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!opt?.refetchInterval || opt?.refetchInterval == 0) {
      return;
    }

    const interval = setInterval(() => {
      fetchData();
    }, opt.refetchInterval);

    return () => clearInterval(interval);
  }, []);

  return { data, loading, error, refetch: fetchData };
};

export default useApi;
