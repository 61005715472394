import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import { useSecurePersistState } from '../hook/state/usePersistSecureState';
import { Player } from '../shared';
import usePersistState from '../hook/state/usePersistState';
import { useTabActive } from '../hook/events/useTabActive';
import { useSmartAccount } from '../hook/web3/useSmartAccount';
import useLazyApi from '../hook/api/useLazyApi';

const H12 = 12 * 1000 * 60 * 60;

type AuthStatus = 'unset' | 'authenticated' | 'unauthenticated';

interface IAuthContext {
  nickName: string;
  keyPairs?: PlayerKeyPairs;
  player: Player | undefined;
  deckKeys?: string[];
  authenticated: AuthStatus;
  setKeyPairs: (keyPair: PlayerKeyPairs | undefined) => void;
  clearAuth: () => void;
  setAuthenticated: (status: AuthStatus) => void;
  setAuth: (player: Player) => void;
  setPlayer: (player: Player | undefined) => void;
  setDeckKeys: (keys: string[]) => void;
  reload: () => void;
}

interface PlayerKeyPairs {
  encryptionKeyPair?: KeyPair;
  signingKeyPair?: KeyPair;
  challengeMessage: string;
  signedMessage: string;
}

interface KeyPair {
  publicKey: string;
  privateKey: string;
}

const defaultValue = {} as IAuthContext;

const AuthContext = React.createContext<IAuthContext>(defaultValue);
export const useAuthContext = () => useContext(AuthContext);

export function AuthContextProvider({ children }: any) {
  const { fetch: rotation } = useLazyApi<string, any>('POST', 'auth/rotation');

  const {
    state: keyPairs,
    setState: setKeyPairs,
    initialized: init1,
    reload: reloadKeyPairs,
  } = useSecurePersistState<PlayerKeyPairs>('key-pairs', undefined);

  const {
    state: deckKeys,
    setState: setDeckKeys,
    initialized: init2,
    reload: reloadDeckKeys,
  } = useSecurePersistState<string[]>('deckKeys', undefined);

  const {
    state: player,
    setState: setPlayer,
    reload: reloadPlayer,
  } = usePersistState<Player | undefined>('player', undefined);

  const [authenticated, setAuthenticated] = useState<AuthStatus>('unset');
  const { wagmiIsConnected, address, ownerAddress } = useSmartAccount();
  const { isTabActive } = useTabActive();

  const nickName = useMemo(() => {
    if (!address) {
      return;
    }

    return address.substring(0, 8);
  }, [address]);

  const reload = useCallback(() => {
    reloadDeckKeys();
    reloadKeyPairs();
    reloadPlayer();
  }, [reloadDeckKeys, reloadKeyPairs, reloadPlayer]);

  const clearAuth = useCallback(async () => {
    setAuthenticated('unauthenticated');
    setPlayer(undefined);
  }, [setPlayer]);

  const setAuth = useCallback(
    (player: Player) => {
      setAuthenticated('authenticated');
      setPlayer(player);
    },
    [setPlayer]
  );

  useEffect(() => {
    if (!wagmiIsConnected && authenticated == 'authenticated') {
      clearAuth();
    }
  }, [authenticated, wagmiIsConnected, clearAuth]);

  useEffect(() => {
    if (authenticated && ownerAddress && player) {
      if (player.id != ownerAddress) {
        clearAuth();
      }
    }
  }, [authenticated, ownerAddress, player, clearAuth]);

  useEffect(() => {
    if (isTabActive && init1 && init2) {
      reload();
    }
  }, [isTabActive, init1, init2, reload]);

  useEffect(() => {
    if (authenticated != 'authenticated') {
      return;
    }

    const rotationInterval = H12;

    const interval = setInterval(() => {
      rotation()
        .then((success) => {
          if (success) {
            console.info('jwt was renew suceessfully');
          }
        })
        .catch((err) => {
          console.error(`failed to rotate jwt, err:${err}`);
        });
    }, rotationInterval);

    return () => clearTimeout(interval);
  }, [authenticated, rotation]);

  useEffect(() => {
    if (wagmiIsConnected && authenticated == 'unset')
      rotation()
        .then((success) => {
          if (success) {
            console.info('Authenticated');
            setAuthenticated('authenticated');
          }
        })
        .catch(() => {
          console.error(`failed to rotate jwt`);
        });
  }, [wagmiIsConnected, authenticated, setAuthenticated, rotation]);

  if (!init1 || !init2) {
    return <></>;
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        nickName: nickName || '',
        keyPairs,
        deckKeys,
        player,
        setKeyPairs,
        setDeckKeys,
        setAuthenticated,
        clearAuth,
        setAuth,
        setPlayer,
        reload,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
