import { useCallback } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';
import { Card, CardKeys, RoundStage } from '../../shared';
import useGameRules from './useGameRules';

export const useGameValidation = () => {
  const { state } = usePlayerContext();
  const { playerCardsIndexes } = useGameRules();

  const validateRevealCards = useCallback((cards: Card[]) => {
    return cards.every((c) => c >= 2 && c <= 53);
  }, []);

  const validateRevealKeyPlayerCardsEvent = useCallback(
    async (roundId: string) => {
      const round = state.gameRound;
      if (!round || round.id != roundId) {
        console.error('did not find active round');
        return false;
      }
      if (round.roundStage != RoundStage.DealCards) {
        console.error(`Reveal player card event not valid on this stage: ${round.roundStage}`);
        return false;
      }

      return true;
    },
    [state]
  );

  const validatePlayerRevealHisCardEvent = useCallback(
    async (cardsKeys: CardKeys[], playerAddress: string) => {
      const { gameRound, table } = state;
      if (!gameRound || !table?.type) {
        console.error('did not find active round');
        return false;
      }

      if (gameRound.roundStage != RoundStage.Showdown && gameRound.roundStage != RoundStage.Presentation) {
        console.error(`Player reveal his cards event not valid on this stage: ${gameRound.roundStage}`);
        return false;
      }

      const cardsIndexes = playerCardsIndexes(playerAddress, gameRound, table?.type.gameType);
      if (cardsKeys.every((ck) => cardsIndexes.some((index) => ck.index == index))) {
        return true;
      }

      return false;
    },
    [state, playerCardsIndexes]
  );

  const validatePlayerTimeToRevealCardsEvent = useCallback(
    async (myAddress: string) => {
      const { gameRound, table } = state;
      if (!gameRound || !table?.type) {
        console.error('did not find active round');
        return false;
      }

      const activePlayers = gameRound.players.filter((p) => p.status !== 'folded');
      if (activePlayers.length > 1 || activePlayers.every((p) => p.status == 'all-in')) {
        return true;
      }

      if (gameRound.roundStage != RoundStage.Showdown && gameRound.roundStage != RoundStage.Presentation) {
        console.error(`Reavel player card not valid on this stage ${gameRound.roundStage}`);
        return false;
      }

      const myPlayer = gameRound.players.find((p) => p.address == myAddress);
      if (myPlayer?.status == 'folded') {
        return false;
      }

      return true;
    },
    [state]
  );

  const validateRevealCommunityCardsEvent = useCallback(
    async (roundId: string, stage: RoundStage) => {
      const round = state.gameRound;
      if (!round || round.id != roundId) {
        console.error('did not find active round');
        return false;
      }
      if (round.roundStage !== stage) {
        console.error(`the round stage: ${round.roundStage} not equal to this stage ${stage}`);
        return false;
      }

      return true;
    },
    [state]
  );

  return {
    validateRevealKeyPlayerCardsEvent,
    validateRevealCommunityCardsEvent,
    validatePlayerRevealHisCardEvent,
    validatePlayerTimeToRevealCardsEvent,
    validateRevealCards,
  };
};
