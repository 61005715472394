import { useCallback, useEffect, useMemo, useState } from 'react';
import { Player, RevealCard, BackupCardsPayload, GameRound } from '../../shared';
import { useWsContext } from '../../context/WebSocketContext';
import { useAuthContext } from '../../context/AuthContext';
import { useP2PEventContext } from '../../context/P2PEventContext';
import { useOnlinePlayer } from './useOnlinePlayer';
import usePersistState from '../state/usePersistState';
import useMental from '../cryptography/useMental';
import { useTabActive } from './useTabActive';
import { usePlayerContext } from '../../context/PlayerContext';
import useRevealMyCards from './useRevealMyCards';

const P2P_EVENT_TIMEOUT = 4000;

interface CardsState {
  roundId: string;
  cards: RevealCard[];
}

interface PlayerCardPart {
  cardIndex: number;
  keyIndex: number;
  part: string;
}

interface PlayerCardParts {
  parts: PlayerCardPart[];
}

interface Backupper {
  [key: string]: number;
}

export const useBackupCards = () => {
  const [blacklist, setBlacklist] = useState<string[]>([]);
  const [backuped, setBackuped] = useState(false);
  const [keyState, setKeyState] = useState<CardsState>();
  const { state: backupers, setState: setBackupers, reload } = usePersistState<Backupper>('cards-backupers', {});
  const { reveal } = useRevealMyCards();
  const { player } = useAuthContext();
  const {
    state: { gameRound },
  } = usePlayerContext();
  const { subscribe: subscribeP2P, emit: emitP2P, ready: readyP2P } = useP2PEventContext();
  const { subscribe, ready } = useWsContext();
  const { shareKey, totalShares } = useMental();
  const { onlinePlayers, loading, fetch } = useOnlinePlayer();
  const { isTabActive } = useTabActive();

  const myAdress = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const backupCards = useCallback(
    async (round: GameRound) => {
      const cards = await reveal();
      if (!cards) {
        return;
      }

      setBackuped(false);
      setBackupers({});
      setBlacklist([]);
      fetch();

      setKeyState({
        roundId: round.id,
        cards,
      });
    },
    [setBackuped, setBackupers, setBlacklist, setKeyState, fetch, reveal]
  );

  useEffect(() => {
    if (!gameRound) {
      return;
    }

    if (!keyState?.roundId || keyState.roundId != gameRound.id) {
      backupCards(gameRound);
    }
  }, [gameRound, keyState, backupCards]);

  const partsPromise = useMemo(async () => {
    if (!keyState) {
      return [];
    }

    const playerCardParts: PlayerCardParts[] = [];
    for (let x = 0; x < totalShares; x++) {
      playerCardParts.push({
        parts: [],
      });
    }

    for (const rc of keyState.cards) {
      for (let keyIndex = 0; keyIndex < rc.keys.length; keyIndex++) {
        const parts = await shareKey(rc.keys[keyIndex]);

        for (const partIndex in parts) {
          playerCardParts[partIndex].parts.push({
            cardIndex: rc.index,
            keyIndex: keyIndex,
            part: parts[partIndex],
          });
        }
      }
    }

    return playerCardParts;
  }, [shareKey, totalShares, keyState]);

  const availablePlayers = useMemo(() => {
    if (loading || onlinePlayers.length == 0) {
      return;
    }

    return onlinePlayers.filter((p) => p.id !== myAdress && !blacklist.includes(p.id) && !backupers[p.id]);
  }, [onlinePlayers, loading, blacklist, backupers, myAdress]);

  const addBackuper = useCallback(
    (backuper: string, partIdx: number) => {
      setBackupers((prev) => {
        return {
          ...prev,
          [backuper]: partIdx,
        };
      });
    },
    [setBackupers]
  );

  const sendBackupP2P = useCallback(
    async (parts: PlayerCardPart[], dstPlayer: Player) => {
      if (!readyP2P || !emitP2P || !subscribeP2P || !keyState) {
        return;
      }

      return new Promise<boolean>((resolve) => {
        const timeout = setTimeout(() => {
          unsubscribe();
          resolve(false);
        }, P2P_EVENT_TIMEOUT);

        const unsubscribe = subscribeP2P<string>('backup-cards-ack', (_, message) => {
          if (message.from == dstPlayer.id) {
            unsubscribe();
            clearTimeout(timeout);
            resolve(true);
          }
        });

        emitP2P<BackupCardsPayload>('backup-cards', dstPlayer.encryptionPublicKey, dstPlayer.id, {
          cardsParts: parts,
          roundId: keyState.roundId,
        });
      });
    },
    [readyP2P, subscribeP2P, emitP2P, keyState]
  );

  const backupPart = useCallback(async () => {
    if (!backupers || !availablePlayers) {
      return;
    }

    if (availablePlayers.length == 0) {
      console.debug('abort backup cards not find available player');
      return;
    }

    const keyParts = await partsPromise;

    const randomIndex = Math.floor(Math.random() * availablePlayers.length);
    const dstPlayer = availablePlayers[randomIndex];

    console.debug(`picked backuper: ${dstPlayer.id}`);

    const backupedParts = Object.values(backupers);

    const remainingParts = keyParts.filter((_, i) => backupedParts.indexOf(i) === -1);
    if (remainingParts.length === 0) {
      return;
    }

    const part = remainingParts.shift()!;

    const success = await sendBackupP2P(part.parts, dstPlayer);
    if (!success) {
      console.debug(`failed sending key parts to ${dstPlayer}`);
      setBlacklist((prev) => [...prev, dstPlayer.id]);
      return;
    }

    const partIdx = keyParts!.indexOf(part);
    addBackuper(dstPlayer.id, partIdx);

    console.debug(`sent part index: ${partIdx} to ${dstPlayer.id}`);
  }, [partsPromise, backupers, availablePlayers, addBackuper, setBlacklist, sendBackupP2P]);

  useEffect(() => {
    if (!backupers) {
      return;
    }
    if (backupers.size >= totalShares) {
      console.debug('Im fully cards backuped');
      setBackuped(true);
      return;
    }

    setBackuped(false);
    backupPart();
  }, [backupPart, backupers, totalShares]);

  useEffect(() => {
    if (!ready || !subscribe) {
      return;
    }

    const unsubscribe = subscribe<Player>('player-disconnected', (player: Player) => {
      setBackupers((prev) => {
        if (!prev[player.id]) {
          return prev;
        }

        console.debug(`player ${player.id} disconnected. backupers: ${prev}`);
        const newBackupers = {
          ...prev,
        };
        delete newBackupers[player.id];
        return newBackupers;
      });
    });

    return () => unsubscribe();
  }, [ready, setBackupers, subscribe]);

  useEffect(() => {
    if (isTabActive) {
      reload();
    }
  }, [isTabActive, reload]);

  return { backuped };
};
