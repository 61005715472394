import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { WsEvent, WsMsg } from '../shared';
import { useAuthContext } from './AuthContext';
import { useExponentialBackoff } from '../hook/helper/useExponentialBackoff';
import { useTabActive } from '../hook/events/useTabActive';

const wsUrl = process.env.REACT_APP_SERVER_HOST;
const protocol = process.env.REACT_APP_HTTPS === 'true' ? 'wss://' : 'ws://';
const KEEP_ALIVE_INTERVAL = 1000 * 15;

interface IWsContext {
  subscribe?: <T>(wsEvent: WsEvent, listener: (payload: T) => void) => () => void;
  emit?: <T>(wsEvent: WsEvent, payload?: T) => void;
  ready: boolean;
}

const defaultValue = {
  ready: false,
} as IWsContext;

const WebSocketContext = React.createContext<IWsContext>(defaultValue);
export const useWsContext = () => useContext(WebSocketContext);

export function WebSocketProvider({ children }: any) {
  const { authenticated } = useAuthContext();
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const { isTabActive } = useTabActive();
  //const { nextDelay, currentDelay } = useExponentialBackoff({ initialDelay: 1, factor: 2, maxDelay: 1000 });

  const channel = useMemo(() => new BroadcastChannel('ws-connection-channel'), []);

  const connectToWS = useCallback(() => {
    const ws = new WebSocket(`${protocol}${wsUrl}`);
    setWebsocket((prev) => {
      if (prev) {
        prev.close();
      }
      return ws;
    });

    const keepAliveInterval = setInterval(() => {
      const msg: WsMsg<any> = {
        event: 'keep-alive',
      };

      ws.send(JSON.stringify(msg));
    }, KEEP_ALIVE_INTERVAL);

    ws.onopen = () => {
      console.info('WebSocket connected');
      try {
        channel.postMessage('ws-connected');
      } catch (error) {
        console.error('failed to send ws-connceted on BroadcastChannel');
      }
    };

    ws.onclose = (ev) => {
      console.error(`WebSocket disconnected,code: ${ev.code}, reason: ${ev.reason}`);
      setWebsocket(null);
      clearInterval(keepAliveInterval);
      try {
        channel.postMessage('ws-disconnected');
      } catch (error) {
        console.error('failed to send ws-connceted on BroadcastChannel');
      }
    };
  }, [channel]);

  useEffect(() => {
    if (authenticated === 'authenticated' && !websocket && isTabActive) {
      setTimeout(() => {
        connectToWS();
      }, 1000);
    }

    channel.onmessage = (event) => {
      if (event.data === 'ws-connected') {
        if (websocket) {
          console.warn('close ws, following event ws-connected');
          websocket.close();
        }
      } else if (event.data === 'ws-disconnected' && isTabActive) {
        if (!websocket) {
          console.warn('close ws,following event ws-disconnected and tab is active');
          connectToWS();
        }
      }
    };
  }, [authenticated, websocket, connectToWS, isTabActive, channel]);

  const unsubscribe = useCallback(
    (listener: (message: MessageEvent) => void) => {
      websocket?.removeEventListener('message', listener);
    },
    [websocket]
  );

  const subscribe = useCallback(
    <T,>(wsEvent: WsEvent, listener: (payload: T) => void) => {
      const handler = (message: MessageEvent) => {
        const { event, payload } = JSON.parse(message.data) as WsMsg<T>;
        if (event === wsEvent) {
          listener(payload as T);
        }
      };
      websocket?.addEventListener('message', handler);

      return () => unsubscribe(handler);
    },
    [websocket, unsubscribe]
  );

  const emit = useCallback(
    <T,>(wsEvent: WsEvent, payload: T) => {
      if (websocket && websocket.readyState === WebSocket.OPEN) {
        const msg = {
          event: wsEvent,
          payload: payload,
        } as WsMsg<T>;

        websocket.send(JSON.stringify(msg));
      } else {
        console.warn('WebSocket is not connected.');
      }
    },
    [websocket]
  );

  return (
    <WebSocketContext.Provider value={websocket ? { subscribe, emit, ready: true } : defaultValue}>
      {children}
    </WebSocketContext.Provider>
  );
}
