import { useEffect } from 'react';
import { GameRound, RestorePlayerCommunityKeysRequest, RestorePlayerCommunityKeysResponse } from '../../shared';
import { BackupKeyPayload } from '../../shared';
import { useWsContext } from '../../context/WebSocketContext';
import { useP2PEventContext } from '../../context/P2PEventContext';
import usePersistState from '../state/usePersistState';
import { useTabActive } from './useTabActive';

interface PlayerKeyPartMap {
  [key: string]: BackupKeyPayload;
}

export const useBakupperKey = () => {
  const { subscribe: subscribeP2P, emit: emitP2P, ready: readyP2P } = useP2PEventContext();
  const { subscribe, emit, ready } = useWsContext();
  const { isTabActive } = useTabActive();

  const { state: keyParts, setState: setKeyParts, reload } = usePersistState<PlayerKeyPartMap>('keyParts', {});

  useEffect(() => {
    if (!readyP2P || !subscribeP2P) {
      return;
    }

    const unsubscribe = subscribeP2P<BackupKeyPayload>('backup-key', (_, message) => {
      console.debug(`recieved backup request from ${message.from}, part: ${message.payload}`);
      setKeyParts((prev) => {
        return {
          ...prev,
          [message.from]: message.payload,
        };
      });
      emitP2P!('backup-key-ack', message.encryptionPublicKey, message.from, true);
    });

    return () => unsubscribe();
  }, [readyP2P, subscribeP2P, emitP2P, setKeyParts]);

  useEffect(() => {
    if (!ready || !subscribe) {
      return;
    }

    const unsubscribe = subscribe<GameRound>('game-round-ended', (round: GameRound) => {
      setKeyParts((prev) => {
        const keys = Object.keys(prev).filter((k) => prev[k].roundId == round.id);
        if (keys.length == 0) {
          return prev;
        }

        console.debug(`will remove key prats from round ${round.id}.`);
        const newKeyParts = {
          ...prev,
        };
        keys.forEach((key) => {
          delete newKeyParts[key];
        });

        return newKeyParts;
      });
    });

    return () => unsubscribe();
  }, [ready, subscribe, setKeyParts]);

  useEffect(() => {
    if (!ready || !keyParts || !emit) {
      return;
    }

    const unsubscribe = subscribe!<RestorePlayerCommunityKeysRequest>(
      'restore-player-community-keys-request',
      (request: RestorePlayerCommunityKeysRequest) => {
        console.debug(`recieved restore reqeust for ${request.playerAddress}`);

        if (!keyParts[request.playerAddress]) {
          return;
        }

        const part = keyParts[request.playerAddress];
        if (!part) {
          return;
        }

        if (part.roundId != request.roundId) {
          return;
        }

        emit<RestorePlayerCommunityKeysResponse>('restore-player-community-keys-response', {
          roundId: part.roundId,
          keyPart: part.keyParts,
          playerAddress: request.playerAddress,
        });
        console.debug(`sent ${part} to server`);
      }
    );

    return () => unsubscribe();
  }, [ready, emit, subscribe, keyParts]);

  useEffect(() => {
    if (isTabActive) {
      reload();
    }
  }, [isTabActive, reload]);
};
