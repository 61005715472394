import React, { CSSProperties, useEffect, useMemo, useState, useCallback } from 'react';
import { CashTableType, Table } from '../../shared';
import { Slider, Box, Button, Typography } from '@mui/joy';
import palette from '../../styles/theme';
import { useCashContract } from '../../hook/web3/useCashContract';
import Loading from '../Loading/Loading';
import useLazyApi from '../../hook/api/useLazyApi';
import { isProd } from '../../helper/env-helper';
import { useTranslation } from 'react-i18next';

interface CashDepositProps {
  tableType: CashTableType;
  onClose: () => void;
}

const CashDeposit: React.FC<CashDepositProps> = ({ tableType, onClose }) => {
  const [amount, setAmount] = useState<number>(0);
  const { t: transactionT } = useTranslation('translation', { keyPrefix: 'transaction.progress.state' });
  const { t: depositT } = useTranslation('translation', { keyPrefix: 'deposit' });
  const { isLoading, error, cashIn, reset, progressState, isSucces } = useCashContract();
  const { fetch: sendRequest } = useLazyApi<Table, any>('POST', 'dev/join');

  const round = useCallback((round: number) => {
    return Number(round.toFixed(2));
  }, []);

  const min = useMemo(() => {
    return tableType.minBuyIn;
  }, [tableType]);

  const max = useMemo(() => {
    return tableType.maxBuyIn;
  }, [tableType]);

  useEffect(() => {
    setAmount(min);
    reset();
  }, [setAmount, reset, min]);

  const step = tableType.big;

  const marks = useMemo(() => {
    const avg = round((max + min) / 2);
    return [
      {
        value: min,
        label: `$${min.toString()}`,
      },
      {
        value: avg,
        label: `$${avg.toString()}`,
      },
      {
        value: max,
        label: `$${max.toString()}`,
      },
    ];
  }, [round, min, max]);

  const devJoinTable = useCallback(() => {
    sendRequest({
      typeId: tableType.id,
      amount: amount,
    })
      .catch(() => {})
      .then(() => onClose());
  }, [sendRequest, onClose, tableType, amount]);

  const cashInToTable = useCallback(() => {
    cashIn(tableType, amount).catch((error) => {
      console.error(error);
    });
  }, [cashIn, amount, tableType]);

  useEffect(() => {
    if (isSucces) {
      onClose();
    }
  }, [isSucces, onClose]);

  return (
    <>
      {isLoading ? (
        <>
          {progressState && (
            <Typography sx={{ color: palette.text.primary }} level="body-lg">
              {`${transactionT(progressState)} `}
            </Typography>
          )}
          <Loading size={120} />
        </>
      ) : (
        <>
          <Typography sx={{ color: palette.text.primary }} level="body-sm">
            {`${depositT('cash-in')}`}
          </Typography>
          <Slider
            color="warning"
            getAriaValueText={(v) => `$${v}`}
            step={step}
            defaultValue={amount}
            value={amount}
            min={min}
            max={max}
            marks={marks}
            valueLabelDisplay="off"
            onChange={(e, v) => setAmount(Array.isArray(v) ? v[0] : (v as number))}
          />

          <Box
            sx={{
              marginTop: 'auto',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {error && (
              <Typography sx={{ marginBottom: 2 }} textAlign="center" color="danger" level="title-sm">
                {error}
              </Typography>
            )}
            <Button
              color="primary"
              fullWidth
              sx={styles.button}
              variant="soft"
              onClick={() => (isProd ? cashInToTable() : devJoinTable())}
            >
              {`${depositT('cash-in')} $${amount}`}
            </Button>
            <Typography
              sx={{ marginTop: 2, color: palette.text.secondary, textAlign: 'center', fontSize: '0.6rem' }}
              level="body-sm"
            ></Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default CashDeposit;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    height: 320,
    backgroundColor: palette.primary.solidBg,
    borderRadius: 30,
    border: 'solid',
    borderColor: palette.primary.solidBorder,
    borderWidth: 0.5,
    boxShadow: '24',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    p: 4,
  } as CSSProperties,
  button: {
    color: palette.text.primary,
    hover: { color: 'transparent' },
  },
  lineSpace: {
    marginTop: 15,
  } as CSSProperties,
};
