import React, { useEffect, useMemo, useState } from 'react';
import { CashTablePlayer, CashTableType, RoundStage } from '../../shared';
import { Button } from '@mui/joy';
import palette from '../../styles/theme';
import Rebuy from './Rebuy';
import { usePlayerContext } from '../../context/PlayerContext';
import { useAuthContext } from '../../context/AuthContext';

interface RebuyButtonProps {
  hideBtn?: boolean;
}

const RebuyButton: React.FC<RebuyButtonProps> = ({ hideBtn }) => {
  const [open, setOpen] = useState(false);
  const { state } = usePlayerContext();
  const { player } = useAuthContext();

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const { table } = state;
  const players: CashTablePlayer[] = useMemo(
    () => (table ? Object.keys(table.players).map((k) => table.players[k]) : []),
    [table]
  );
  const myPlayer = useMemo(() => players.find((p) => p.address == address), [players, address]);
  const myBalance = useMemo(() => {
    if (!myPlayer || myPlayer.balance == undefined) {
      return undefined;
    }
    return Number(myPlayer.balance.toFixed(2));
  }, [myPlayer]);

  const canRebuy = useMemo(() => {
    if (myBalance === undefined || !table) {
      return false;
    }

    const cashTableType = table.type as CashTableType;
    if (myBalance < cashTableType.maxBuyIn && cashTableType.maxBuyIn - myBalance > cashTableType.big * 5) {
      return true;
    }

    return false;
  }, [table, myBalance]);

  useEffect(() => {
    if (!canRebuy || myBalance === undefined || !table) {
      return;
    }

    if (myBalance < table.type.big && myPlayer?.status == 'sit-up') {
      setOpen(true);
    }
  }, [myBalance, myPlayer, table, canRebuy, state]);

  if (!table || !canRebuy) {
    return <></>;
  }

  return (
    <>
      {!hideBtn && (
        <Button
          color="primary"
          size="sm"
          sx={{
            borderRadius: '5%',
            lineHeight: 0,
            height: '34px',
            fontSize: '14px',
            color: palette.text.secondary,
            hover: { color: 'transparent', borderColor: palette.text.secondary },
          }}
          onClick={() => setOpen(true)}
          variant="solid"
        >
          {'Rebuy'}
        </Button>
      )}
      <Rebuy
        open={open}
        onClose={() => setOpen(false)}
        playerBalance={myBalance || 0}
        tableId={table.id}
        tableType={table.type}
      />
    </>
  );
};

export default RebuyButton;
