import { useEffect, useMemo } from 'react';
import { useWsContext } from '../../context/WebSocketContext';
import { SubscribeEvent, Table, UnsubscribeEvent } from '../../shared';
import { useAuthContext } from '../../context/AuthContext';

export const useReadOnlyTableEvent = (table?: Table) => {
  const { emit } = useWsContext();
  const { player } = useAuthContext();

  const roundId = useMemo(() => table?.roundId, [table]);

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const readOnly = useMemo(() => {
    return table?.players[address] == undefined;
  }, [table, address]);

  useEffect(() => {
    if (!readOnly || !emit || !table) {
      return;
    }

    emit<SubscribeEvent>('subscribe', {
      topic: 'table-changed',
      filter: table.id,
    });

    return () =>
      emit<UnsubscribeEvent>('unsubscribe', {
        topic: 'table-changed',
      });
  }, [readOnly, table, emit]);

  useEffect(() => {
    if (!readOnly || !emit || !table?.tournamentId) {
      return;
    }

    emit<SubscribeEvent>('subscribe', {
      topic: 'tournament-event-changed',
      filter: table.tournamentId,
    });

    return () =>
      emit<UnsubscribeEvent>('unsubscribe', {
        topic: 'tournament-event-changed',
      });
  }, [readOnly, table, emit]);

  useEffect(() => {
    if (!readOnly || !emit || !roundId) {
      return;
    }

    emit<SubscribeEvent>('subscribe', {
      topic: 'game-round-changed',
      filter: roundId,
    });

    return () =>
      emit<UnsubscribeEvent>('unsubscribe', {
        topic: 'game-round-changed',
      });
  }, [readOnly, roundId, emit]);

  return { readOnly };
};
