import { useEffect } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';
import { useWsContext } from '../../context/WebSocketContext';
import { FuncComposite } from '../../helper/composite';
import { Table, GameRound, RoundStage } from '../../shared';
import { useModal } from '../../context/ModalContext';
import { KickoutPlayerInconsistency } from '../../components/PlayerInconsistency/KickoutPlayerInconsistency';
import { useTranslation } from 'react-i18next';
import { PlayerInconsistency } from '../../components/PlayerInconsistency/PlayerInconsistency';

export const useTableEventHandler = () => {
  const { subscribe } = useWsContext();
  const { showModal } = useModal();
  const { updateState, clearRoundStates } = usePlayerContext();
  const { t: inconsistencyT } = useTranslation('translation', { keyPrefix: 'player.inconsistency' });
  const { t: kickoutInconsistencyT } = useTranslation('translation', { keyPrefix: 'kickout.player.inconsistency' });

  useEffect(() => {
    if (!subscribe) {
      return;
    }

    const unsubscribe = new FuncComposite();

    unsubscribe.add(
      subscribe<Table>('table-changed', (payload: Table) => {
        updateState({
          table: payload,
        });
      })
    );

    unsubscribe.add(
      subscribe<GameRound>('game-round-changed', (payload: GameRound) => {
        if (payload.roundStage === RoundStage.Finish) {
          clearRoundStates();
          return;
        }

        updateState({
          gameRound: payload,
        });
      })
    );

    unsubscribe.add(
      subscribe('player-inconsistency', () => {
        showModal(inconsistencyT('title'), PlayerInconsistency, {});
      })
    );

    unsubscribe.add(
      subscribe('kickout-player-inconsistency', () => {
        showModal(kickoutInconsistencyT('title'), KickoutPlayerInconsistency, {});
      })
    );

    return () => unsubscribe.run();
  }, [subscribe, updateState, clearRoundStates, showModal, inconsistencyT, kickoutInconsistencyT]);
};
