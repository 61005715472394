// useApi.ts
import { useState, useCallback } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, Method } from 'axios';
import { Route } from '../type';
import { useAuthContext } from '../../context/AuthContext';

interface ApiHook<T, K> {
  data: T | null;
  loading: boolean;
  success: boolean;
  called: boolean;
  code: number | undefined;
  error: AxiosError | null;
  fetch: (body?: K) => Promise<T | undefined>;
}

const baseUrl = process.env.REACT_APP_SERVER_HOST;
const protocol = process.env.REACT_APP_HTTPS == 'true' ? 'https://' : 'http://';

const useLazyApi = <T, K>(method: Method, route: Route, options?: AxiosRequestConfig): ApiHook<T, K> => {
  const [data, setData] = useState<T | null>(null);
  const { clearAuth } = useAuthContext();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [code, setCode] = useState<number | undefined>(undefined);
  const [called, setCalled] = useState(false);

  const fetch = useCallback(
    async (body?: K) => {
      options = options || {};
      options.withCredentials = true;

      const url = `${protocol}${baseUrl}/${route}`;
      setLoading(true);
      setError(null);
      setCode(undefined);
      try {
        let response: AxiosResponse<T>;
        if (method === 'POST') {
          response = await axios.post<T>(url, body, options);
        } else {
          response = await axios.get<T>(url, options);
        }
        if (response.data) {
          setData(response.data);
        }
        setSuccess(true);

        return response.data;
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        setError(axiosError);
        setCode(axiosError?.response?.status);
        console.debug(axiosError?.response?.status);
        if (axiosError?.response?.status === 401) {
          console.debug('will clear auth');
          clearAuth();
        }
        throw error;
      } finally {
        setLoading(false);
        setCalled(true);
      }
    },
    [route, method, options, clearAuth]
  );

  return { data, code, loading, error, success, fetch, called };
};

export default useLazyApi;
