import { useMemo } from 'react';
import { useNetworkConfig } from './useNetworkConfig';
import { ContractType } from './type';
import { useSmartAccount } from './useSmartAccount';
//import { useSmartAccount } from './useSmartAccount';

export const useContractAddress = (contractType: ContractType) => {
  const { getNetworkConfig } = useNetworkConfig();
  const { chainId } = useSmartAccount();

  const contractAddress = useMemo(() => {
    const config = getNetworkConfig(chainId.toString());

    switch (contractType) {
      case 'cash-game':
        return config?.contracts.CashGameCashier.address || '0x24';
      case 'tournament':
        return config?.contracts.TournamentCashier.address || '0x25';
      default:
        return '0x26';
    }
  }, [getNetworkConfig, chainId, contractType]);

  return { contractAddress };
};
