import React from 'react';
import EthIcon from '../../assets/coins/eth.svg';
import ArbhIcon from '../../assets/coins/arb.svg';
import MaticIcon from '../../assets/coins/matic.svg';
import UsdtIcon from '../../assets/coins/usdt.svg';
import UsdcIcon from '../../assets/coins/usdc.svg';
import { Box } from '@mui/joy';

interface CoinIconProps {
  name: string;
  chainId?: number;
}

interface index {
  [key: string]: any;
}

const coinMap: index = {
  eth: EthIcon,
  arb: ArbhIcon,
  matic: MaticIcon,
  usdt: UsdtIcon,
  usdc: UsdcIcon,
};

const chainMap: index = {
  1: EthIcon,
  42161: ArbhIcon,
  137: MaticIcon,
};

export const CoinIcon: React.FC<CoinIconProps> = ({ name, chainId }) => {
  return (
    <Box>
      <img src={coinMap[name.toLowerCase()]} alt={name} style={{ width: '3vh', height: '3vh' }} />
      {chainId && (
        <img
          src={chainMap[chainId]}
          alt={name}
          style={{ bottom: 8, left: 10, position: 'absolute', width: '2vh', height: '2vh' }}
        />
      )}
    </Box>
  );
};
