import { useCallback } from 'react';
import { useWriteContract } from 'wagmi';
import { ethers } from 'ethers';
import { IERC20Factory } from '../../shared';
import { Address } from './type';
import { useSmartAccount } from './useSmartAccount';

export const useERC20Transfer = () => {
  const { writeContractAsync } = useWriteContract();
  const { smartClient } = useSmartAccount();

  const transfer = useCallback(
    async (tokenAddress: Address, to: Address, amount: number) => {
      try {
        if (!smartClient) {
          console.warn(`smart client undefined`);
          return false;
        }

        const iface = new ethers.Interface(IERC20Factory.abi);
        const data = iface.encodeFunctionData('transfer', [to, ethers.parseUnits(amount.toFixed(6), 6)]);

        const tx = await smartClient.sendUserOperation({
          uo: {
            target: tokenAddress as `0x${string}`,
            data: data as `0x${string}`,
            value: 0n,
          },
        });

        // await writeContractAsync({
        //   abi: IERC20Factory.abi,
        //   address: tokenAddress,
        //   functionName: 'transfer',
        //   args: [to, ethers.parseUnits(amount.toFixed(6), 6)],

        // });
        if (!tx.hash) {
          console.error(`transaction failed`);
          return false;
        }

        return true;
      } catch (error) {
        console.error(`failed to approve, err: ${error}`);
        return false;
      }
    },
    [writeContractAsync]
  );

  return { transfer };
};
