import { LinearProgress, Box } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';
import { TableType } from '../../shared';
import palette from '../../styles/theme';

interface TurnTimerProps {
  visible: boolean;
  tableType: TableType;
}

const TurnTimer: React.FC<TurnTimerProps> = ({ visible, tableType }) => {
  const {
    state: { gameRound },
  } = usePlayerContext();
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (!visible || !gameRound || gameRound.currentPlayerExp === -1) {
      setProgress(0);
      return;
    }

    const buffer = 2;
    const playerTurnTimeout = tableType.playerTurnTimeout - buffer;
    const initialTimeLeft = (gameRound.currentPlayerExp - Date.now()) / 1000 - buffer;
    const totalTime = playerTurnTimeout || initialTimeLeft; // Use playerTurnTimeout if available

    if (initialTimeLeft > 0) {
      setProgress(100);
    } else {
      setProgress(0);
      return;
    }

    const interval = setInterval(() => {
      if (!gameRound?.currentPlayerExp) {
        clearInterval(interval);
        return;
      }
      const currentTimeLeft = (gameRound.currentPlayerExp - Date.now()) / 1000 - buffer;
      if (currentTimeLeft <= 0) {
        setProgress(-1);
        clearInterval(interval);
      } else {
        const progressPercent = (currentTimeLeft / totalTime) * 100;
        setProgress(progressPercent);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [visible, gameRound, tableType.playerTurnTimeout]);

  const getMainColor = (progress: number) => {
    if (progress > 66) return 'success'; // Green
    if (progress > 33) return 'warning'; // Yellow
    return 'danger'; // Red
  };

  const getColor = (progress: number) => {
    const baseColor = getMainColor(progress);
    const colors = {
      success: palette.success.solidBg, // Light green
      warning: palette.warning.solidBg, // Light yellow
      danger: palette.danger.solidBg, // Stronger red
    };
    return colors[baseColor];
  };

  const getBackgroundColor = (progress: number) => {
    const baseColor = getMainColor(progress);
    const colors = {
      success: 'rgba(76, 175, 80, 0.1)', // Light green
      warning: 'rgba(255, 193, 7, 0.1)', // Light yellow
      danger: 'rgba(255, 59, 48, 0.1)', // Stronger red
    };
    return colors[baseColor];
  };

  if (!visible || progress <= 0) {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        height: `100%`,
        backgroundColor: getBackgroundColor(progress),
        borderRadius: '20px 20px 20px 20px',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: `${Math.max(Number(progress), 0)}%`,
          height: '99%',
          borderRadius: '20px 20px 20px 20px',
          backgroundColor: getColor(progress),
          transition: 'width 0.3s ease-in-out',
        }}
      />
    </div>
  );
  // <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
  //   <LinearProgress
  //     determinate
  //     variant="soft"
  //     color={getMainColor(progress)}
  //     thickness={15}
  //     value={Math.max(Number(progress), 0)}
  //     sx={{
  //       '--LinearProgress-radius': '20px',
  //       '--LinearProgress-thickness': '100%',
  //       bgcolor: getBackgroundColor(progress),
  //     }}
  //   />
  // </Box>
};

export default TurnTimer;
