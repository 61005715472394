import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { SwiperSlide } from 'swiper/react';
import React, { useMemo } from 'react';
import View from '../View/View';
import useApi from '../../hook/api/useApi';
import { CashTableType, GameFormat, GetTableTypePayload, TableType, TournamentTableType } from '../../shared';
import TournamentDeposit from '../Deposit/TournamentDeposit';
import CashDeposit from '../Deposit/CashDeposit';
import CashTableCard from './CashTableCard';
import { Box, Typography } from '@mui/joy';
import palette from '../../styles/theme';
import Loading from '../Loading/Loading';
import discord from '../../assets/discord.svg';
import twitter from '../../assets/twitter.svg';
import telegram from '../../assets/telegram.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../hook/helper/useNavigate';
import TourneyTableCard from './TourneyTableCard';
import { useModal } from '../../context/ModalContext';
import Slider from '../Slider/Slider';
import { useResponsive } from '../../hook/helper/useResponsive';

interface TableTypeListProps {
  gameFormat: GameFormat;
}

const socialLinks = [
  {
    goTo: 'https://t.me/ztpoker',
    icon: telegram,
  },
  {
    goTo: 'https://discord.gg/Y3ncVAeuqc',
    icon: discord,
  },
  {
    goTo: 'https://x.com/ZeroTrustPoker',
    icon: twitter,
  },
];

const TableTypeList: React.FC<TableTypeListProps> = ({ gameFormat }) => {
  const { t } = useTranslation('translation', { keyPrefix: gameFormat });
  const { isMobile } = useResponsive();
  const { navNewTab } = useNavigate();
  const { showModal } = useModal();

  const { data: tableTypes, loading } = useApi<TableType[], GetTableTypePayload>(
    'POST',
    'table-types',
    {
      gameFormat,
    },
    {
      refetchInterval: 1000 * 60,
    }
  );

  const filtered = useMemo(() => {
    if (!tableTypes) {
      return [];
    }

    if (isMobile) {
      return tableTypes.filter((t) => t.state == 'active');
    }

    return tableTypes
      .filter((t) => t.state !== 'hide')
      .sort((a, b) => {
        const activeA = a.state == 'active';
        const activeB = b.state == 'active';
        if (activeA === activeB) return 0;
        return activeA ? -1 : 1;
      });
  }, [tableTypes, isMobile]);

  if (loading) {
    return <Loading size={120} />;
  }

  return (
    <Box height={'100%'} display={'flex'} flexDirection={'column'}>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ color: palette.text.primary, marginBottom: 7 }} level="h1">
          {t('title').toUpperCase()}
        </Typography>
        {filtered.length === 0 ? (
          <Box>
            <Typography sx={{ color: palette.text.secondary, marginTop: '20px', textAlign: 'center' }} level="body-md">
              {`Our tables are currently closed.`}
            </Typography>
            <Typography sx={{ color: palette.text.secondary, marginTop: '4px', textAlign: 'center' }} level="body-md">
              {`Join our community channels for upcoming games, tournaments and announcements`}
            </Typography>
          </Box>
        ) : (
          <>
            <Slider>
              {filtered.map((tableType) => (
                <SwiperSlide key={tableType.id}>
                  {gameFormat == 'cash' ? (
                    <CashTableCard
                      active={tableType.state == 'active'}
                      tableType={tableType as CashTableType}
                      onSelect={() => {
                        showModal(tableType.name, CashDeposit, {
                          tableType: tableType as CashTableType,
                        });
                      }}
                    />
                  ) : (
                    <TourneyTableCard
                      active={tableType.state == 'active'}
                      tableType={tableType as TournamentTableType}
                      onSelect={(tourneyEvent) => {
                        showModal(tableType.name, TournamentDeposit, {
                          tournamentEvent: tourneyEvent,
                        });
                      }}
                    />
                  )}
                </SwiperSlide>
              ))}
            </Slider>
            {filtered.every((t) => t.state != 'active') && (
              <Box>
                <Typography
                  sx={{ color: palette.text.secondary, marginTop: '20px', textAlign: 'center' }}
                  level="body-md"
                >
                  {`Our games are currently closed.`}
                </Typography>
                <Typography
                  sx={{ color: palette.text.secondary, marginTop: '4px', textAlign: 'center' }}
                  level="body-md"
                >
                  {`Join our community channels for upcoming games, tournaments and announcements`}
                </Typography>
              </Box>
            )}
          </>
        )}
      </View>
      <Box display={'flex'} marginTop={'20px'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
        {socialLinks.map((item, index) => (
          <div key={index} style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => navNewTab(item.goTo)}>
            <img src={item.icon} alt="icon" />
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default TableTypeList;
