import { useState, useEffect, useMemo } from 'react';
import { useSoundEffect } from './useSoundEffect';
import { usePlayerContext } from '../../context/PlayerContext';
import { useAuthContext } from '../../context/AuthContext';
import { PokerActionType, PotWinners, RoundStage } from '../../shared';
import { usePlayerLastAction } from '../games/usePlayerLastAction';

export const useCashGameSoundEffect = (potWinners?: PotWinners) => {
  const { play } = useSoundEffect();
  const { state, settings } = usePlayerContext();
  const { player } = useAuthContext();
  const { action, lastPlayer } = usePlayerLastAction();

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const round = state?.gameRound;

  // Initialize soundPlayed with explicit keys from RoundStage
  const [soundPlayed, setSoundPlayed] = useState({
    [RoundStage.DealCards]: false,
    [RoundStage.PreFlop]: false,
    [RoundStage.Flop]: false,
    [RoundStage.Turn]: false,
    [RoundStage.River]: false,
    [RoundStage.Showdown]: false,
    [RoundStage.Presentation]: false,
    [RoundStage.Finish]: false,
  });

  //const myTurn = useMemo(() => round?.currentPlayerTurn === address, [round, address]);
  const iWin = useMemo(
    () => (potWinners?.winners || []).some((w) => w.playerAddress == address),
    [potWinners, address]
  );

  useEffect(() => {
    // Reset all sound flags when the round starts anew
    if (round?.roundStage === RoundStage.DealCards) {
      setSoundPlayed({
        [RoundStage.DealCards]: false,
        [RoundStage.PreFlop]: false,
        [RoundStage.Flop]: false,
        [RoundStage.Turn]: false,
        [RoundStage.River]: false,
        [RoundStage.Showdown]: false,
        [RoundStage.Presentation]: false,
        [RoundStage.Finish]: false,
      });
    }
  }, [round?.roundStage]);

  // useEffect(() => {
  //   if (!settings.soundEnabled) return;

  //   if (myTurn) {
  //     play('player-turn');
  //   }
  // }, [myTurn, play, settings]);

  useEffect(() => {
    if (!action) {
      return;
    }

    switch (action) {
      case PokerActionType.Check:
        play('check');
        break;
      case PokerActionType.Call:
        play('call');
        break;
      case PokerActionType.Raise:
        play('raise');
        break;
      case PokerActionType.AllIn:
        play('allin');
        break;
      case PokerActionType.Fold:
        play('fold');
        break;
    }
  }, [action, lastPlayer, play]);

  useEffect(() => {
    if (!settings.soundEnabled) return;

    if (iWin) {
      play('winner');
    }
  }, [iWin, play, settings]);

  useEffect(() => {
    const stage = round?.roundStage;
    if (!settings.soundEnabled || stage === undefined) return;

    if (!soundPlayed[stage]) {
      switch (stage) {
        case RoundStage.DealCards:
          play('dealing-player-cards');
          break;
        case RoundStage.Flop:
          play('dealing-flop');
          break;
        case RoundStage.Turn:
          play('dealing-turn');
          break;
        case RoundStage.River:
          play('dealing-river');
          break;
        // Handle other stages similarly
      }
      setSoundPlayed((prev) => ({ ...prev, [stage]: true }));
    }
  }, [round, play, settings, soundPlayed]);
};
