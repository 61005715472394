import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChatMessage } from '../../shared';
import { usePlayerContext } from '../../context/PlayerContext';
import { useWsContext } from '../../context/WebSocketContext';
import { useAuthContext } from '../../context/AuthContext';
import SendIcon from '@mui/icons-material/Send';
import { Styles } from '../../styles/type';
import { IconButton, Input, ListDivider, Typography } from '@mui/joy';
import palette from '../../styles/theme';
import { useTranslation } from 'react-i18next';
import { usePlayerNickname } from '../../hook/helper/usePlayerNickname';

interface ChatProps {
  setUnreadMessage: (val: boolean) => void;
  visable: boolean;
}

const Chat: React.FC<ChatProps> = ({ setUnreadMessage, visable }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'chat' });
  const {
    state: { table },
  } = usePlayerContext();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const { player } = useAuthContext();
  const { nickname } = usePlayerNickname(player);
  const { subscribe, emit } = useWsContext();
  const [newMessage, setNewMessage] = useState('');
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  useEffect(() => {
    if (visable && inputRef.current) {
      const inputElement = inputRef.current?.querySelector('input');
      inputElement?.focus();
    }
  }, [visable]);

  useEffect(() => {
    if (visable && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, visable]);

  useEffect(() => {
    if (!subscribe) {
      return;
    }

    const unsubscribe = subscribe<ChatMessage>('player-chat', (payload: ChatMessage) => {
      if (!table) {
        return;
      }

      // this case can occur when a user is sitting in multiple tables
      if (table.id !== payload.tableId) {
        return;
      }

      setMessages((messages) => [...messages, payload]);

      if (payload.senderAddress === address) {
        setNewMessage('');
      } else {
        if (!visable) {
          setUnreadMessage(true);
        }
      }
    });

    return () => unsubscribe();
  }, [subscribe, setNewMessage, setMessages, setUnreadMessage, visable, address, table]);

  const handleSendMessage = () => {
    if (!emit || !table) {
      return;
    }

    if (newMessage.trim() === '') {
      return;
    }

    const newChatMessage: ChatMessage = {
      tableId: table.id,
      senderNickname: nickname,
      senderAddress: address,
      text: newMessage,
    };

    emit<ChatMessage>('player-chat', newChatMessage);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  if (!visable) {
    return <></>;
  }

  return (
    <div style={styles.container}>
      <div style={styles.chatContainer} ref={chatContainerRef}>
        {messages.map((message, index) => (
          <div key={index} style={styles.messageContainer}>
            <Typography sx={{ color: palette.text.primary }} level="body-md">
              {`${message.senderNickname}: ${message.text}`}
            </Typography>
            <ListDivider />
          </div>
        ))}
      </div>
      <div style={styles.inputContainer}>
        <Input
          ref={inputRef}
          sx={styles.inputField}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder={`${t('placeholder')}`}
          variant="solid"
        />
        <IconButton onClick={handleSendMessage}>
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};

const styles: Styles = {
  container: {
    position: 'fixed',
    flexDirection: 'column',
    display: 'flex',
    height: '30vh',
    width: '35vw',
    bottom: '7vh',
    left: '1vw',
  },
  messageContainer: {
    margin: '0.2vw',
    wordWrap: 'break-word',
  },
  chatContainer: {
    border: 'solid',
    borderWidth: '0.1vh',
    borderRadius: '1vh',
    background: palette.primary.solidBg,
    overflowY: 'auto',
    height: '100%',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    marginTop: '0.6vh',
    display: 'flex',
    flexDirection: 'row',
  },
  inputField: {
    width: '100%',
    marginTop: '0.6vh',
    padding: '0.5',
  },
  sendButton: {
    padding: '0.2vw 0.5vw',
    cursor: 'pointer',
  },
};

export default Chat;
