import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, ButtonGroup, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import palette from '../../styles/theme';
import { usePlayerContext } from '../../context/PlayerContext';
import { SxProps } from '@mui/material';
import { useCardText } from '../Card/useCardText';
import { useP2PEventContext } from '../../context/P2PEventContext';
import { CardKeys, PlayerRevealHisCardsPayload, RevealCard, RoundStage } from '../../shared';
import { useAuthContext } from '../../context/AuthContext';
import useRevealMyCards from '../../hook/events/useRevealMyCards';

interface PostRoundActionsProps {}

const PostRoundActions: React.FC<PostRoundActionsProps> = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'post.round.action' });
  const [revealed, setRevealded] = useState(false);
  const { player } = useAuthContext();
  const { reveal } = useRevealMyCards();
  const { emit } = useP2PEventContext();
  const { cardToText, cardColor } = useCardText();
  const {
    state: { table, gameRound },
    updateState,
  } = usePlayerContext();
  const [myCards, setMyCards] = useState<RevealCard[]>([]);

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const resolveCards = useCallback(async () => {
    const cards = await reveal();
    if (cards) {
      setMyCards(cards);
    }
  }, [reveal]);

  useEffect(() => {
    if (myCards.length == 0) {
      resolveCards();
    }
  }, [myCards, resolveCards]);

  const canRevealCards = useMemo(() => {
    if (!gameRound || revealed || !myCards || myCards.length < 2) {
      return false;
    }

    if (gameRound.roundStage !== RoundStage.Presentation) {
      return false;
    }

    const roundPlayer = gameRound.players.find((p) => p.address == address);
    const leftPlayers = gameRound.players.filter((p) => p.status !== 'folded').length;
    const iamOnlyLefy = roundPlayer?.status != 'folded' && leftPlayers == 1;

    if (roundPlayer?.status === 'folded' || iamOnlyLefy) {
      return true;
    }

    return false;
  }, [gameRound, address, revealed, myCards]);

  const emitRevealCradsEvent = useCallback(
    async (cardsKeys: CardKeys[]) => {
      if (!emit || !table) {
        return;
      }

      updateState({
        myPlayerRevealCard: true,
      });

      for (const player of Object.values(table.players)) {
        if (player.address == address) {
          continue;
        }

        emit<PlayerRevealHisCardsPayload>(
          'player-reveal-his-cards',
          player.player.encryptionPublicKey,
          player.address,
          {
            cardsKeys,
          }
        );
      }
    },
    [emit, updateState, table, address]
  );

  const revealAllCards = useCallback(async () => {
    await emitRevealCradsEvent(myCards);
    setRevealded(true);
  }, [emitRevealCradsEvent, myCards]);

  const revealOneCard = useCallback(
    async (index: number) => {
      await emitRevealCradsEvent(myCards.filter((r) => r.index == index));
      setRevealded(true);
    },
    [emitRevealCradsEvent, myCards]
  );

  if (!canRevealCards) {
    return <></>;
  }

  return (
    <Box sx={styles.container}>
      <ButtonGroup sx={{ alignSelf: 'flex-end', width: '100%', flex: 1 }} variant="plain" spacing={'0.5rem'}>
        <Button sx={styles.button} size="lg" color="primary" onClick={() => revealOneCard(myCards[0].index)}>
          <Box display={'flex'} flexDirection="row">
            <Typography sx={{ color: palette.text.dark }} level="body-sm">
              {t('reveal')}
            </Typography>
            <Typography sx={{ marginLeft: '5px', color: cardColor(myCards[0].card) }} level="body-md">
              {cardToText(myCards[0].card)}
            </Typography>
          </Box>
        </Button>
        <Button sx={styles.button} size="lg" color="primary" onClick={() => revealOneCard(myCards[1].index)}>
          <Box display={'flex'} flexDirection="row">
            <Typography sx={{ color: palette.text.dark }} level="body-sm">
              {t('reveal')}
            </Typography>
            <Typography sx={{ marginLeft: '5px', color: cardColor(myCards[1].card) }} level="body-md">
              {cardToText(myCards[1].card)}
            </Typography>
          </Box>
        </Button>
        <Button sx={styles.button} size="lg" color="primary" onClick={() => revealAllCards()}>
          <Box display={'flex'} flexDirection="row">
            <Typography sx={{ color: palette.text.dark }} level="body-sm">
              {t('reveal')}
            </Typography>
            <Typography sx={{ marginLeft: '5px', color: cardColor(myCards[0].card) }} level="body-md">
              {cardToText(myCards[0].card)}
            </Typography>
            <Typography sx={{ marginLeft: '5px', color: cardColor(myCards[1].card) }} level="body-md">
              {cardToText(myCards[1].card)}
            </Typography>
          </Box>
        </Button>
      </ButtonGroup>
    </Box>
  );
};

const styles = {
  container: {
    width: '100%',
    flexDirection: 'row' as const,
    display: 'flex',
    flexFlow: 'row',
    flex: 1,
  } as SxProps,
  button: {
    color: palette.text.primary,
    backgroundColor: palette.neutral.softBg,
    hover: { color: 'transparent' },
    maxHeight: '34px',
    minHeight: '34px',
    width: 'auto',
    minWidth: '90px',
    padding: '4px',
    fontWeight: '400',
    textAlign: 'center',
    justifyContent: 'center',
    flex: 1,
  } as SxProps,
};

export default PostRoundActions;
