import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { CardKeys, GameRoundPlayer, RevealCard } from '../../shared';
import CardView from './CardView'; // Assuming Card is in the same directory
import ReversedCard from './ReversedCard';
import { Box } from '@mui/joy';
import { motion } from 'framer-motion';
import { usePlayerContext } from '../../context/PlayerContext';
import useGameRules from '../../hook/games/useGameRules';
import useRevealMyCards from '../../hook/events/useRevealMyCards';

export interface CardPairProps {
  roundPlayer: GameRoundPlayer;
}

const CardPair: React.FC<CardPairProps> = ({ roundPlayer }) => {
  const { player } = useAuthContext();
  const { playerCardsNumber } = useGameRules();
  const { reveal } = useRevealMyCards();

  const {
    state: { myPlayerRevealCard, playerRevealCards, table },
  } = usePlayerContext();
  const [myCards, setMyCards] = useState<RevealCard[]>();

  const myAddress = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const resolveCards = useCallback(async () => {
    const cards = await reveal();
    if (cards) {
      setMyCards(cards);
    }
  }, [reveal]);

  useEffect(() => {
    if (!myCards) {
      resolveCards();
    }
  }, [myCards, resolveCards]);

  const myPlayer = useMemo(
    () => myAddress.toLocaleLowerCase() === roundPlayer?.address?.toLowerCase(),
    [myAddress, roundPlayer]
  );

  const nPlayerCards = useMemo(() => {
    if (!table?.type) {
      return 2;
    }
    return playerCardsNumber(table.type.gameType);
  }, [table?.type, playerCardsNumber]);

  const showCards = useMemo(() => {
    if (roundPlayer.status != 'folded' || myPlayerRevealCard) {
      return true;
    }
    return false;
  }, [roundPlayer, myPlayerRevealCard]);

  const playerCards = useMemo(() => {
    if (playerRevealCards && playerRevealCards[roundPlayer.address]?.length > 0) {
      return playerRevealCards[roundPlayer.address];
    }

    if (roundPlayer.clearCards?.length == nPlayerCards) {
      return roundPlayer.clearCards.map((c) => c.card);
    }

    return [];
  }, [roundPlayer, playerRevealCards, nPlayerCards]);

  if (myPlayer) {
    if (myCards && myCards.length > 1) {
      return (
        // <motion.div
        //   style={{ width: '100%', height: '100%', paddingTop: showCards ? '0px' : '62px' }}
        //   whileHover={{ paddingTop: '0px' }}
        //   transition={{ type: 'spring', stiffness: 400, damping: 30 }}
        // >
        <motion.div
          style={{ width: '100%', height: '100%', opacity: showCards ? '100%' : '0%' }}
          whileHover={{ opacity: '100%' }}
          transition={{ duration: 0.3 }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
            <CardView card={myCards[0].card} size="small" />
            <CardView card={myCards[1].card} size="small" />
          </Box>
        </motion.div>
      );
    } else if (showCards) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
          <ReversedCard />
          <ReversedCard />
        </Box>
      );
    }
  }

  if (playerCards?.length > 0) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
        <CardView card={playerCards[0]} size="small" />
        {playerCards?.length > 1 ? <CardView card={playerCards[1]} size="small" /> : <ReversedCard />}
      </Box>
    );
  } else if (showCards) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
        <ReversedCard />
        <ReversedCard />
      </Box>
    );
  }
};

export default CardPair;
