import { useCallback, useEffect, useMemo } from 'react';
import { MenatalCrypto } from '../../helper/MentalCrypto';
import { Card, CardKeys, CardsDeck, GameType, KeyIndex, RevealCard } from '../../shared';
import { useAuthContext } from '../../context/AuthContext';
import useCryptoApi from './useCryptoApi';
import usePersistState from '../state/usePersistState';
import useGameRules from '../games/useGameRules';

const useMental = () => {
  const { state: shuffleKey, setState: setShuffleKey } = usePersistState<string>('shuffle-key', '');
  const { playerCardsNumber } = useGameRules();
  const { deckKeys, setDeckKeys } = useAuthContext();
  const { decrypt, encrypt, encryptWithMyKey } = useCryptoApi();
  const crypto = useMemo(() => new MenatalCrypto(), []);

  useEffect(() => {
    if (!shuffleKey) {
      setShuffleKey(crypto.generateKey());
    }
  }, [shuffleKey, setShuffleKey, crypto]);

  const generateKey = useCallback(() => {
    const key = crypto.generateKey();
    return key;
  }, [crypto]);

  const generateDeckKeys = useCallback(async () => {
    const keys = Array.from({ length: 52 }, () => crypto.generateKey());

    const encryptedKeys = (await Promise.all(keys.map((key) => encryptWithMyKey(key.toString())))).filter(
      (e) => e != null
    ) as string[];

    if (encryptedKeys.length != 52) {
      throw new Error('failed to encrypt the key');
    }

    setDeckKeys(encryptedKeys);
    return keys;
  }, [setDeckKeys, encryptWithMyKey, crypto]);

  const encryptCard = useCallback(
    (card: Card, key: string): Card => {
      return crypto.encrypt(card, BigInt(key));
    },
    [crypto]
  );

  const decryptCard = useCallback(
    (card: Card, keys: string[]) => {
      let decryptedCard = card;
      for (const key of keys) {
        decryptedCard = crypto.decrypt(decryptedCard, BigInt(key));
      }
      return decryptedCard;
    },
    [crypto]
  );

  const backupCommunityCardsKeys = useCallback(
    (gameType: GameType, keys: string[], numOfPlayers: number) => {
      const gamePlayerCards = playerCardsNumber(gameType);
      const playersCardsLength = numOfPlayers * gamePlayerCards;
      const startBackupIndex = playersCardsLength;
      const endBackupIndex = startBackupIndex + 5;
      const backupKeys = keys.slice(startBackupIndex, endBackupIndex).map((k, i) => {
        return {
          key: k,
          index: startBackupIndex + i,
        } as KeyIndex;
      });

      return backupKeys;
    },
    [playerCardsNumber]
  );

  const shuffle = useCallback(
    (deck: number[], passes: number = 1): number[] => {
      for (let pass = 0; pass < passes; pass++) {
        for (let i = deck.length - 1; i > 0; i--) {
          const array = new Uint32Array(1);
          window.crypto.getRandomValues(array);
          const j = array[0] % (i + 1);
          [deck[i], deck[j]] = [deck[j], deck[i]];
        }
      }

      const encryptedDeck = deck.map((card) => {
        return encryptCard(card, shuffleKey);
      });

      return encryptedDeck;
    },
    [shuffleKey, encryptCard]
  );

  const encryptDeck = useCallback(
    async (gameType: GameType, cards: number[], numOfPlayers: number) => {
      const keys = await generateDeckKeys();

      const reEncryptedCards = cards.map((card, i) => {
        const dCard = decryptCard(card, [shuffleKey]);
        return encryptCard(dCard, keys[i]);
      });

      const backupKeys = backupCommunityCardsKeys(gameType, keys, numOfPlayers);

      return { reEncryptedCards, backupKeys };
    },
    [backupCommunityCardsKeys, decryptCard, encryptCard, generateDeckKeys, shuffleKey]
  );

  const shareKey = useCallback(
    (key: string) => {
      return crypto.shareKey(key);
    },
    [crypto]
  );

  const revealKey = useCallback(
    async (index: number) => {
      if (!deckKeys) {
        throw new Error('deckkeys not set');
      }

      const key = await decrypt(deckKeys[index]);

      return key;
    },
    [deckKeys, decrypt]
  );

  const replaceKey = useCallback(
    async (index: number, publicKey: string) => {
      let key = await revealKey(index);
      key = await encrypt(key, publicKey);

      return key;
    },
    [revealKey, encrypt]
  );

  const validateRevealCards = useCallback((cards: Card[]) => {
    return cards.every((c) => c >= 2 && c <= 53);
  }, []);

  const revealCards = useCallback(
    async (cardsKeys: CardKeys[], deck: CardsDeck) => {
      const revealCards: RevealCard[] = [];
      if (!cardsKeys) {
        return revealCards;
      }

      for (const cardKey of cardsKeys) {
        const clearKeys = await Promise.all(cardKey.keys.map((encryptedKey) => decrypt(encryptedKey)));
        const card = decryptCard(deck.cards[cardKey.index], clearKeys);

        revealCards.push({
          card,
          index: cardKey.index,
          keys: clearKeys,
        });
      }
      return revealCards;
    },
    [decryptCard, decrypt]
  );

  return {
    shuffle,
    encryptDeck,
    generateKey,
    generateDeckKeys,
    decryptCard,
    encryptCard,
    decrypt,
    shareKey,
    revealKey,
    replaceKey,
    revealCards,
    validateRevealCards,
    totalShares: crypto.TOTAL_SHARES,
  };
};

export default useMental;
