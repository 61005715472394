import React, { useEffect, useCallback, useMemo } from 'react';
import { RegisterPayload, TournamentEvent, UnregisterPayload } from '../../shared';
import { Box, Button, Typography } from '@mui/joy';
import palette from '../../styles/theme';
import Loading from '../Loading/Loading';
import useLazyApi from '../../hook/api/useLazyApi';
import { isProd } from '../../helper/env-helper';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '../../hook/helper/useDateFormat';
import { useTourneyContract } from '../../hook/web3/useTourneyContract';
import { useAuthContext } from '../../context/AuthContext';
import { usePlayerContext } from '../../context/PlayerContext';

interface TournamentDepositProps {
  tournamentEvent: TournamentEvent;
}

const TournamentDeposit: React.FC<TournamentDepositProps> = ({ tournamentEvent }) => {
  const { player } = useAuthContext();
  const { state } = usePlayerContext();
  const { t: transactionT } = useTranslation('translation', { keyPrefix: 'transaction.progress.state' });
  const { t: tourneyT } = useTranslation('translation', { keyPrefix: 'tourney' });
  const { t: depositT } = useTranslation('translation', { keyPrefix: 'deposit' });
  const { t: gameTypeT } = useTranslation('translation', { keyPrefix: 'gameType' });
  const { shortDateTime } = useDateFormat();
  const { isLoading, error, unregister, register, reset, progressState } = useTourneyContract();
  const { fetch: devUnregisterRequest } = useLazyApi<boolean, UnregisterPayload>('POST', 'dev/unregister');
  const { fetch: devRegisterRequest } = useLazyApi<boolean, RegisterPayload>('POST', 'dev/register');

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const { tourney } = state;
  const tourneyEvent = useMemo(() => {
    const storeTorney = tourney?.find((t) => t.id == tournamentEvent?.id);
    if (!storeTorney) {
      return tournamentEvent;
    }

    if (tournamentEvent && tournamentEvent?.version > storeTorney.version) {
      return tournamentEvent;
    }

    return storeTorney;
  }, [tournamentEvent, tourney]);

  const remainingPlayers = useMemo(() => {
    return tourneyEvent?.players.filter((p) => p.state != 'eliminated').length || 0;
  }, [tourneyEvent]);

  useEffect(() => {
    reset();
  }, [reset]);

  // useEffect(() => {
  //   if (isSucces) {
  //     onClose();
  //   }
  // }, [isSucces, onClose]);

  const devRegister = useCallback(() => {
    if (!tourneyEvent) {
      return;
    }

    devRegisterRequest({
      tournamentId: String(tourneyEvent.id),
    }).catch(() => {});
  }, [devRegisterRequest, tourneyEvent]);

  const tourenyRegister = useCallback(() => {
    if (!tourneyEvent) {
      return;
    }

    const amount = tourneyEvent.state == 'enrollment' ? tourneyEvent.type.buyIn : tourneyEvent.type.reEntry;
    register(amount, String(tourneyEvent.id)).catch((e) => {
      console.error(e);
    });
  }, [register, tourneyEvent]);

  const devUnregister = useCallback(() => {
    if (!tourneyEvent) {
      return;
    }

    devUnregisterRequest({
      tournamentId: String(tourneyEvent.id),
    }).catch(() => {});
  }, [devUnregisterRequest, tourneyEvent]);

  const handleUnregister = useCallback(() => {
    if (isProd) {
      unregister(String(tourneyEvent.id));
    } else {
      devUnregister();
    }
  }, [unregister, devUnregister, tourneyEvent]);

  const nextStep = useMemo(() => {
    const thresholds = tourneyEvent?.type.prizeThreshold || [];
    const currentPlayers = tourneyEvent?.players.length || 0;
    for (let i = 0; i < thresholds.length; i++) {
      if (currentPlayers < thresholds[i].playerCount) {
        return {
          playerCount: thresholds[i].playerCount,
          prizeAmount: thresholds[i].prizeAmount,
        };
      }
    }
    return null;
  }, [tourneyEvent]);

  const playerState = useMemo(() => {
    const player = tourneyEvent?.players?.find((p) => p.address == address);
    if (!player) {
      return 'unregisterd';
    }

    return player.state;
  }, [tourneyEvent, address]);

  const full = useMemo(() => {
    return (
      tourneyEvent &&
      tourneyEvent.type.maxRegisteredPlayers == tourneyEvent.players.filter((p) => p.state != 'eliminated').length
    );
  }, [tourneyEvent]);

  const renderPrizeDistribution = (prizePool: number, prizeDistribution: number[]) => {
    return prizeDistribution.map((percentage, index) => (
      <Box key={index} display={'flex'} marginRight={'12px'} alignItems="center" flexDirection={'column'}>
        <Typography sx={{ color: palette.text.primary }} level="body-md">
          {`${index + 1}${index === 0 ? 'st' : index === 1 ? 'nd' : index === 2 ? 'rd' : 'th'} place`}
        </Typography>
        <Typography sx={{ color: palette.text.secondary }}>
          {`$${((percentage / 100) * prizePool).toFixed(2)}`}
        </Typography>
      </Box>
    ));
  };

  const renderAction = useCallback(() => {
    switch (playerState) {
      case 'eliminated':
      case 'unregisterd': {
        const canRegister =
          (!full && (tourneyEvent?.state == 'enrollment' || tourneyEvent?.state == 'started')) ||
          (tourneyEvent?.state == 'break' && tourneyEvent.stateBeforeBreak == 'started');
        return (
          <Button
            loading={isLoading}
            color={!canRegister ? 'neutral' : 'primary'}
            fullWidth
            disabled={!canRegister}
            sx={styles.button}
            variant="soft"
            onClick={() => (isProd ? tourenyRegister() : devRegister())}
          >
            {tourneyEvent.state == 'enrollment' ? depositT('register') : depositT('re-enter')}
          </Button>
        );
      }
      case 'registered': {
        const canUnregister = tourneyEvent?.state == 'enrollment';
        return (
          <Button
            loading={isLoading}
            disabled={!canUnregister}
            fullWidth
            color={!canUnregister ? 'neutral' : 'primary'}
            sx={styles.button}
            onClick={handleUnregister}
            variant="soft"
          >
            {depositT('unregister')}
          </Button>
        );
      }
      default: {
        return <></>;
      }
    }
  }, [handleUnregister, tourenyRegister, devRegister, isLoading, depositT, playerState, tourneyEvent, full]);

  return (
    <>
      {isLoading ? (
        <>
          {progressState && (
            <Typography sx={{ color: palette.text.primary }} level="body-lg">
              {`${transactionT(progressState)} `}
            </Typography>
          )}
          <Loading size={120} />
        </>
      ) : (
        <>
          <Box
            flex={1}
            display={'flex'}
            width={'auto'}
            maxWidth={'400px'}
            flexDirection={'column'}
            justifyContent={'space-around'}
            marginTop={'0px'}
            alignSelf={'flex-start'}
          >
            <Typography sx={{ color: palette.text.primary }} level="body-md">
              {`${tourneyT('gameType')}: `}
              <Typography sx={{ color: palette.text.secondary }}>{gameTypeT(tourneyEvent.type.gameType)}</Typography>
            </Typography>
            <Typography sx={{ color: palette.text.primary, marginTop: 0.5 }} level="body-md">
              {`${tourneyT('start')}: `}
              <Typography sx={{ color: palette.text.secondary }}>
                {shortDateTime(new Date(tourneyEvent?.startTime))}
              </Typography>
            </Typography>
            <Typography sx={{ color: palette.text.primary, marginTop: 0.5 }} level="body-md">
              {`${tourneyT('close')}: `}
              <Typography sx={{ color: palette.text.secondary }}>
                {shortDateTime(new Date(tourneyEvent?.closeTime))}
              </Typography>
            </Typography>
            <Typography sx={{ color: palette.text.primary, marginTop: 0.5 }} level="body-md">
              {`${tourneyT('buyIn')}: `}
              <Typography sx={{ color: palette.text.secondary }}>
                {tourneyEvent.type.buyIn == 0 ? tourneyT('free') : `$${tourneyEvent.type.buyIn}`}
              </Typography>
            </Typography>
            <Typography sx={{ color: palette.text.primary, marginTop: 0.5 }} level="body-md">
              {`${tourneyT('re-entry')}: `}
              <Typography sx={{ color: palette.text.secondary }}> {`$${tourneyEvent.type.reEntry}`}</Typography>
            </Typography>

            <Typography sx={{ color: palette.text.primary, marginTop: 0.5 }} level="body-md">
              {`${tourneyT('min.players')}: `}
              <Typography sx={{ color: palette.text.secondary }}>{tourneyEvent.type.minRegisteredPlayers}</Typography>
            </Typography>

            <Typography sx={{ color: palette.text.primary, marginTop: 0.5 }} level="body-md">
              {`${tourneyT('max.players')}: `}
              <Typography sx={{ color: palette.text.secondary }}>{tourneyEvent.type.maxRegisteredPlayers}</Typography>
            </Typography>

            {tourneyEvent.state == 'enrollment' ? (
              <Typography sx={{ color: palette.text.primary, marginTop: 0.5 }} level="body-md">
                {`${tourneyT('registered.players')}: `}
                <Typography sx={{ color: palette.text.secondary }}>{tourneyEvent.players.length}</Typography>
              </Typography>
            ) : (
              <Typography sx={{ color: palette.text.primary, marginTop: 0.5 }} level="body-md">
                {`${tourneyT('remaining.players')}: `}
                <Typography sx={{ color: palette.text.secondary }}>
                  {`${tourneyEvent.players.filter((p) => p.state != 'eliminated').length}/${remainingPlayers}`}
                </Typography>
              </Typography>
            )}
            <Typography sx={{ color: palette.text.primary, marginTop: 0.5 }} level="body-md">
              {`${tourneyT('prize')}: `}
              <Typography sx={{ color: palette.text.secondary }} component="span">
                {`$${tourneyEvent.prizePool}`}
              </Typography>
            </Typography>
            {nextStep && (
              <Typography sx={{ color: palette.text.primary, marginTop: 0.5 }} level="body-md">
                {`Next Step: `}
                <Typography sx={{ color: palette.text.secondary }} component="span">
                  {`Players > ${nextStep.playerCount} -> $${nextStep.prizeAmount}`}
                </Typography>
              </Typography>
            )}
            <Box display="flex" flexDirection={'row'} flexWrap={'wrap'} marginTop={1}>
              {renderPrizeDistribution(tourneyEvent.prizePool, tourneyEvent.type.prizeDistribution)}
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: '20px',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {error && (
              <Typography sx={{ marginBottom: 2 }} textAlign="center" color="danger" level="title-sm">
                {error.toString()}
              </Typography>
            )}
            {renderAction()}
          </Box>
        </>
      )}
    </>
  );
};

export default TournamentDeposit;

const styles = {
  button: {
    color: palette.text.primary,
    hover: { color: 'transparent' },
  },
};
