import { useCallback, useEffect, useMemo, useState } from 'react';
import { BackupKeyPayload, KeyIndex, KeyPart, Player } from '../../shared';
import { useWsContext } from '../../context/WebSocketContext';
import { useAuthContext } from '../../context/AuthContext';
import { useP2PEventContext } from '../../context/P2PEventContext';
import { useOnlinePlayer } from './useOnlinePlayer';
import usePersistState from '../state/usePersistState';
import useMental from '../cryptography/useMental';
import { useTabActive } from './useTabActive';

const P2P_EVENT_TIMEOUT = 4000;

interface KeyState {
  keys: KeyIndex[];
  roundId: string;
}

interface KeyParts {
  parts: KeyPart[];
}

interface Backupper {
  [key: string]: number;
}

export const useBackupKeys = () => {
  const [blacklist, setBlacklist] = useState<string[]>([]);
  const [backuped, setBackuped] = useState(false);
  const [keyState, setKeyState] = useState<KeyState>();
  const { state: backupers, setState: setBackupers, reload } = usePersistState<Backupper>('keys-backupers', {});

  const { player } = useAuthContext();
  const { subscribe: subscribeP2P, emit: emitP2P, ready: readyP2P } = useP2PEventContext();
  const { subscribe, ready } = useWsContext();
  const { shareKey, totalShares } = useMental();
  const { onlinePlayers, loading, fetch } = useOnlinePlayer();
  const { isTabActive } = useTabActive();

  const myAdress = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const backup = useCallback(
    (keys: KeyIndex[], roundId: string) => {
      setBackuped(false);
      setBackupers({});
      setBlacklist([]);
      fetch();

      setKeyState({
        roundId,
        keys,
      });
    },
    [setBackuped, setBackupers, setBlacklist, setKeyState, fetch]
  );

  const partsPromise = useMemo(async () => {
    if (!keyState) {
      return [];
    }

    const keyParts: KeyParts[] = [];
    for (let x = 0; x < totalShares; x++) {
      keyParts.push({
        parts: [],
      });
    }

    for (const ki of keyState.keys) {
      const parts = await shareKey(ki.key.toString());

      for (const part of parts) {
        const index = parts.indexOf(part);
        keyParts[index].parts.push({
          index: ki.index,
          part,
        });
      }
    }

    return keyParts;
  }, [keyState, shareKey, totalShares]);

  const availablePlayers = useMemo(() => {
    if (loading || onlinePlayers.length == 0) {
      return;
    }

    return onlinePlayers.filter((p) => p.id !== myAdress && !blacklist.includes(p.id) && !backupers[p.id]);
  }, [onlinePlayers, loading, blacklist, backupers, myAdress]);

  const addBackuper = useCallback(
    (backuper: string, partIdx: number) => {
      setBackupers((prev) => {
        return {
          ...prev,
          [backuper]: partIdx,
        };
      });
    },
    [setBackupers]
  );

  const sendBackupP2P = useCallback(
    async (parts: KeyPart[], dstPlayer: Player) => {
      if (!readyP2P || !emitP2P || !subscribeP2P || !keyState) {
        return;
      }

      return new Promise<boolean>((resolve) => {
        const timeout = setTimeout(() => {
          unsubscribe();
          resolve(false);
        }, P2P_EVENT_TIMEOUT);

        const unsubscribe = subscribeP2P<string>('backup-key-ack', (_, message) => {
          if (message.from == dstPlayer.id) {
            unsubscribe();
            clearTimeout(timeout);
            resolve(true);
          }
        });

        emitP2P<BackupKeyPayload>('backup-key', dstPlayer.encryptionPublicKey, dstPlayer.id, {
          keyParts: parts,
          roundId: keyState.roundId,
        });
      });
    },
    [readyP2P, subscribeP2P, emitP2P, keyState]
  );

  const backupPart = useCallback(async () => {
    if (!backupers || !availablePlayers) {
      return;
    }

    if (availablePlayers.length == 0) {
      console.debug('abort backup not find available player');
      return;
    }

    const keyParts = await partsPromise;

    const randomIndex = Math.floor(Math.random() * availablePlayers.length);
    const dstPlayer = availablePlayers[randomIndex];

    console.debug(`picked backuper: ${dstPlayer.id}`);

    const backupedParts = Object.values(backupers);

    const remainingParts = keyParts.filter((_, i) => backupedParts.indexOf(i) === -1);
    if (remainingParts.length === 0) {
      return;
    }
    console.debug(`remaining parts: ${remainingParts}`);

    const part = remainingParts.shift()!;

    const success = await sendBackupP2P(part.parts, dstPlayer);
    if (!success) {
      console.debug(`failed sending key parts to ${dstPlayer}`);
      setBlacklist((prev) => [...prev, dstPlayer.id]);
      return;
    }

    const partIdx = keyParts!.indexOf(part);
    addBackuper(dstPlayer.id, partIdx);

    console.debug(`sent part index: ${partIdx} to ${dstPlayer.id}`);
  }, [partsPromise, backupers, availablePlayers, addBackuper, setBlacklist, sendBackupP2P]);

  useEffect(() => {
    if (!backupers) {
      return;
    }
    if (backupers.size >= totalShares) {
      console.debug('Im fully backuped');
      setBackuped(true);
      return;
    }

    setBackuped(false);
    backupPart();
  }, [backupPart, backupers, totalShares]);

  useEffect(() => {
    if (!ready || !subscribe) {
      return;
    }

    const unsubscribe = subscribe<Player>('player-disconnected', (player: Player) => {
      setBackupers((prev) => {
        if (!prev[player.id]) {
          return prev;
        }

        console.debug(`player ${player.id} disconnected. backupers: ${prev}`);
        const newBackupers = {
          ...prev,
        };
        delete newBackupers[player.id];
        return newBackupers;
      });
    });

    return () => unsubscribe();
  }, [ready, setBackupers, subscribe]);

  useEffect(() => {
    if (isTabActive) {
      reload();
    }
  }, [isTabActive, reload]);

  return { backuped, backup };
};
