import React from 'react';
import { useResponsive } from '../../hook/helper/useResponsive';
import TableViewMobile from './TableViewMobile';
import TableViewDesktop from './TableViewDesktop';
import { GameRound, Table, TournamentEvent } from '../../shared';

export type TableViewProps = {
  table: Table;
  round: GameRound | undefined;
  tournament?: TournamentEvent;
};

const TableView: React.FC<TableViewProps> = (props) => {
  const { isMobile } = useResponsive();

  return isMobile ? <TableViewMobile {...props} /> : <TableViewDesktop {...props} />;
};

export default TableView;
