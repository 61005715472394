import { useAuthContext } from '../../context/AuthContext';
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';
import { isProd } from '../../helper/env-helper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useLazyApi from '../api/useLazyApi';

export const useDataDogLogger = () => {
  const { authenticated, player } = useAuthContext();
  const [initialized, setInitialized] = useState(false);
  const { fetch } = useLazyApi<string, any>('GET', 'client-config/datadog-token');

  const originalConsole = useMemo(() => {
    return {
      log: console.log.bind(console),
      info: console.info.bind(console),
      warn: console.warn.bind(console),
      debug: console.debug.bind(console),
      error: console.error.bind(console),
    };
  }, []);

  const fetchDatadogToken = useCallback(async () => {
    try {
      const token = await fetch();
      return token;
    } catch (error) {
      console.error('Failed to fetch Datadog token:', error);
      return null;
    }
  }, [fetch]);

  const initializeDatadog = useCallback(async () => {
    const token = await fetchDatadogToken();
    if (token) {
      const config: LogsInitConfiguration = {
        service: 'ztp-frontend',
        clientToken: token,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
      };
      datadogLogs.init(config);
      setInitialized(true);
    } else {
      console.error('Failed to fetch Datadog token after login.');
    }
  }, [fetchDatadogToken]);

  const handleLogging = useCallback(
    (method: 'log' | 'info' | 'warn' | 'debug' | 'error', message?: any, ...optionalParams: any[]) => {
      if (isProd && initialized) {
        if (method == 'debug') {
          return;
        }
        datadogLogs.logger[method](`[${player?.id}] ${message}`, { params: optionalParams });
      }

      originalConsole[method](message, ...optionalParams);
    },
    [originalConsole, initialized, player]
  );

  useEffect(() => {
    if (!isProd) {
      return;
    }

    if (!initialized && authenticated == 'authenticated') {
      initializeDatadog();
    }
  }, [authenticated, initialized, initializeDatadog]);

  useEffect(() => {
    if (!isProd) {
      return;
    }

    console.log = (message?: any, ...optionalParams: any[]) => handleLogging('log', message, ...optionalParams);
    console.info = (message?: any, ...optionalParams: any[]) => handleLogging('info', message, ...optionalParams);
    console.debug = (message?: any, ...optionalParams: any[]) => handleLogging('debug', message, ...optionalParams);
    console.warn = (message?: any, ...optionalParams: any[]) => handleLogging('warn', message, ...optionalParams);
    console.error = (message?: any, ...optionalParams: any[]) => handleLogging('error', message, ...optionalParams);
  }, [handleLogging]);
};
